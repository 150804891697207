import { createStore, createLogger } from 'vuex'

import authenticationStore from './AuthenticationStore'
import crudStore from './CrudStore'
import messagesStore from './MessagesStore'
import overlaysStore from './OverlaysStore'
import settingsStore from './SettingsStore'

const debug = process.env.NODE_ENV !== 'production'

const options = {
  modules: {
    authentication: authenticationStore,
    crud: crudStore,
    messages: messagesStore,
    overlays: overlaysStore,
    settings: settingsStore
  },
  strict: debug,
  plugins: []
}

if (debug) options.plugins.push(createLogger())

export default createStore(options)
