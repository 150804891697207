import crudService from '@/services/CrudService'

const entity = {
  name: {
    singular: 'Order Item',
    plural: 'OrderItems'
  },
  collection: 'orderItems',
  tableHeaders: [
    { title: 'Quantity', value: 'quantity' },
    { title: 'Product', value: 'product.name' },
    { title: 'Price', value: 'price' },
    { title: 'Total', value: 'total' }
  ],
  model: {
    order: '',
    price: '',
    quantity: '',
    product: ''
  },
  listFields: {
    fields: ['order', 'product', 'quantity', 'price', 'total'],
    populate: ['product']
  },
  schema: {
    fields: [
      {
        label: 'Order',
        model: 'order',
        type: 'hidden',
        class: 'col-lg-12'
      },
      {
        label: 'Product',
        model: 'product',
        class: 'col-lg-12',
        type: 'select',
        options: async () => await crudService('products').getOptions()
      },
      {
        label: 'Quantity',
        model: 'quantity',
        type: 'number',
        class: 'col-lg-4'
      },
      {
        label: 'Price',
        model: 'price',
        class: 'col-lg-8'
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
