const entity = {
  name: {
    singular: 'Carrier',
    plural: 'Carriers'
  },
  collection: 'carriers',
  tableHeaders: [
    { title: 'Name', value: 'name' },
    { title: 'Phone', value: 'landline' },
    { title: 'Email', value: 'email' }
  ],
  model: {
    mcNumber: '',
    dotNumber: '',
    notes: '',
    name: '',
    email: '',
    landline: '',
    cellphone: '',
    address: '',
    address_two: '',
    neighborhood: '',
    city: '',
    state: '',
    zipcode: '',
    loads: [],
    trucks: [],
    drivers: [],
    loadBoards: []
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      { label: 'MC Number', model: 'mcNumber', class: 'col-lg-12' },
      { label: 'DOT Number', model: 'dotNumber', class: 'col-lg-12' },
      { label: 'Notes', model: 'notes', class: 'col-lg-12', type: 'textarea' }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Details',
        fields: [
          { type: 'text-field', label: 'Name', model: 'name', class: 'col-lg-12' },
          { type: 'text-field', label: 'Email', model: 'email', class: 'col-lg-6' },
          { type: 'text-field', label: 'Phone', model: 'landline', class: 'col-lg-3' },
          { type: 'text-field', label: 'Cellphone', model: 'cellphone', class: 'col-lg-3' },
          { type: 'text-field', label: 'Address', model: 'address', class: 'col-lg-8' },
          { type: 'text-field', label: 'Address2', model: 'address_two', class: 'col-lg-3' },
          { type: 'text-field', label: 'Neighborhood', model: 'neighborhood', class: 'col-lg-3' },
          { type: 'text-field', label: 'City', model: 'city', class: 'col-lg-5' },
          { type: 'text-field', label: 'State', model: 'state', class: 'col-lg-1' },
          { type: 'text-field', label: 'Zip', model: 'zipcode', class: 'col-lg-3' }
        ]
      },
      {
        name: 'Drivers',
        groupType: 'list',
        entity: 'drivers',
        itemsPerPage: 50,
        fields: ['name', 'mobilePhone'],
        tableHeaders: [
          { title: 'Name', value: 'name' },
          { title: 'Mobile Phone', value: 'mobilePhone' },
          { title: 'Actions', value: 'action', sortable: false }
        ],
        relatedCollection: 'carriers',
        relatedCollectionIdField: 'carrier',
        editOnDrawer: true
      },
      {
        name: 'Trucks',
        fields: [
          {
            label: 'Trucks',
            type: 'array',
            model: 'trucks',
            arrayOf: {
              fields: [
                { label: 'Make', model: 'make', class: 'col-lg-2' },
                { label: 'Model', model: 'model', class: 'col-lg-2' },
                { label: 'Type', model: 'truckType', class: 'col-lg-2' },
                { label: 'Capacity', model: 'capacity', class: 'col-lg-2' },
                { label: 'Position', model: 'position', required: true }
              ]
            }
          }
        ]
      },
      {
        name: 'LoadBoards',
        fields: [
          {
            label: 'Load Boards',
            type: 'array',
            model: 'loadBoards',
            arrayOf: {
              fields: [
                { label: 'LoadBoard', model: 'loadBoard', class: 'col-lg-4' },
                { label: 'Username', model: 'username', class: 'col-lg-3' },
                { label: 'Password', model: 'password', class: 'col-lg-3' },
                { label: 'Login URL', model: 'loginUrl', class: 'col-lg-12' },
                { label: 'Load List URL', model: 'loadsUrl', class: 'col-lg-12' },
                { label: 'Load Details URL', model: 'detailsUrl', class: 'col-lg-12 ' },
                { label: 'Position', model: 'position', required: true }
              ]
            }
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
