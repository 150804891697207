const entity = {
  name: {
    singular: 'Menu',
    plural: 'Menus'
  },
  collection: 'menus',
  tableHeaders: [
    { title: 'Título', value: 'title' },
    { title: 'Actions', value: 'action', sortable: false }
  ],
  model: {
    title: null,
    key: '',
    items: []
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'text-field',
        label: 'Título',
        model: 'title',
        required: true,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'text-field',
        label: 'Chave',
        model: 'key',
        required: true,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Data de Entrada',
        model: 'date_in',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Data de Saída',
        model: 'date_out',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    fields: [
      {
        label: 'Items',
        model: 'items',
        type: 'array',
        translations: {
          title: 'Título',
          description: 'URL',
          position: 'Posição'
        },
        arrayOf: {
          fields: [
            {
              type: 'text-field',
              inputType: 'text',
              label: 'Título',
              model: 'name',
              class: 'col-lg-6',
              showClass: 'col-lg-3'
            },
            {
              type: 'text-field',
              inputType: 'text',
              label: 'Ícone',
              model: 'icon',
              class: 'col-lg-3',
              showClass: 'col-lg-3'
            },
            {
              type: 'switch',
              model: 'target',
              label: 'Window',
              checkedText: 'New',
              uncheckedText: 'Same',
              class: 'col-lg-3',
              showClass: 'col-lg-3'
            },
            {
              type: 'text-field',
              inputType: 'text',
              label: 'URL',
              model: 'route',
              class: 'col-lg-12',
              showClass: 'col-lg-3'
            },
            {
              type: 'text-field',
              inputType: 'number',
              label: 'Posição',
              model: 'position'
            }
          ]
        }
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
