import crudService from '@/services/CrudService'
import { useRoute } from 'vue-router'
import entity from '../../../models/base/headless'

const metaConfig = {
  headless: true,
  entity: () => getEntity(),
  requiresPermissionTo: 'headless'
}

export default [
  {
    path: '/:headlessKey',
    meta: {
      ...metaConfig,
      crud: 'list'
    },
    component: () =>
      import(
        /* webpackChunkName: 'headless' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/:headlessKey/new',
    meta: {
      ...metaConfig,
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'headless' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/:headlessKey/:id',
    meta: {
      ...metaConfig,
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'headless' */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
