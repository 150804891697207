import crudService from '../services/CrudService'

const crudStore = {
  namespaced: true,
  state: {
    list: [],
    selectedItem: null,
    selectedModule: null,
    getListOptions: null,
    headlessEntity: null
  },
  mutations: {
    setList: (state, list) => {
      state.list = Array.from(list)
    },
    addItem: (state, item) => {
      state.list.unshift(item)
    },
    delete: (state, id) => {
      state.list = state.list.filter((item) => item.id !== id)
    },
    selectItem: (state, item) => {
      state.selectedItem = item
    },
    clearSelectedItem: (state) => {
      state.selectedItem = null
    },
    selectModule: (state, module) => {
      state.selectedModule = module
    },
    updateStateSection: (state, newData) => {
      state.list.map((item) => {
        if (item.id === newData.id) Object.assign(item.data, item.data)
      })
    },
    updateSelectedItemProperty: (state, data) => {
      if (data.index) {
        console.log('here', data)
        state.selectedItem[data.field][data.index] = data.value
        return
      }

      if (data.field.includes('|')) {
        const fieldPath = data.field.replace('|', '.')
        const [parent, arrayIndex, child] = fieldPath.split('.')
        const val =
          Array.isArray(data.value) && data.value.length > 0
            ? [...data.value]
            : data.value

        state.selectedItem[parent][arrayIndex][child] = val
        return
      }

      if (data.field.includes('.')) {
        const [parent, child] = data.field.split('.')
        state.selectedItem[parent][child] = data.value
        return
      }

      state.selectedItem[data.field] = data.value
    },
    setGetListOptinos: (state, data) => {
      state.getListOptions = data
    },
    setHeadlessEntityAndList: (state, data) => {
      state.headlessEntity = data
    }
  },
  actions: {
    async getItemById({ commit }, data) {
      console.log('.>> crudStore', data)
      const item = await crudService(data.collection, data).getItemById()

      if (item.status === 200 && item.data) {
        item.data.id = item.data._id

        if (!data.ignoreState) commit('selectItem', item.data)

        return item.data
      }

      return false
    },

    async getList({ commit }, data) {
      try {
        const response = await crudService(data.collection).getList(data)
        console.log('store/crud/getList', response)
        if (!response) return

        commit('setList', [])

        const listFormatted = response.data.records.map((item) => {
          item.id = item._id
          return item
        })

        commit('setList', listFormatted)
        commit('setGetListOptinos', data)

        return response
      } catch (error) {
        // console.error(error)
      }
    },

    async refreshList({ dispatch, state }) {
      dispatch('getList', state.getListOptions)
    },

    addToList({ commit }, item) {
      commit('addItem', item)
    },

    async deleteItem({ commit }, data) {
      const response = await crudService(
        data.collection,
        data.item
      ).deleteItem()
      commit('delete', data.item.id)
      return response
    },

    async selectItem({ commit }, item) {
      commit('selectItem', item)
    },

    async createItem({ dispatch }, data) {
      const response = await crudService(
        data.collection,
        data.item
      ).createItem()

      console.log('store/crud/input', response)

      if (data.isRelated) {
        // if we are creating a new item, which is child of another, 
        // doesn't need to add to list and clear selected.

        return response
      }

      if (response.status === 'success') {
        dispatch('addToList', response)
        dispatch('clearSelectedItem')
      }

      return response
    },

    async updateItem({}, data) {
      const response = await crudService(
        data.collection,
        data.item
      ).updateItem()

      console.log('store/crud/update', response)

      return response
    },

    clearSelectedItem({ commit }) {
      commit('selectItem')
    },

    async updateProperty({ commit }, data) {
      commit('updateSelectedItemProperty', data)
    },

    async getHeadlessEntityAndList({ commit }, collection) {
      const results = await crudService(collection).getHeadlessEntityAndList()

      commit('setHeadlessEntityAndList', results.data)
      return results.data
    }
  },
  getters: {
    selectedModule: (state) => state.selectedModule,
    selectedItem: (state) => state.selectedItem,
    listItems: (state) => state.list,
    headlessEntity: (state) => state.headlessEntity
  }
}

export default crudStore
