const entity = {
  name: {
    singular: 'Contato',
    plural: 'Contatos'
  },
  options: {
    importCSV: true
  },
  collection: 'contacts',
  tableHeaders: [
    { title: 'Nome', value: 'fullName' },
    { title: 'CPF', value: 'cpf' },
    { title: 'Celular', value: 'cellphone' },
    { title: 'Bairro', value: 'neighborhood' },
    { title: 'Cidade', value: 'city' },
    { title: 'Religião', value: 'religiao' }
  ],
  model: {
    firstName: '',
    fullName: '',
    cpf: '',
    email: '',
    cellphone: '',
    address: '',
    neighborhood: '',
    zipcode: '',
    city: '',
    state: '',
    status: {},
    accountId: '',
    additionalData: []
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        type: 'switch',
        label: 'Ingresso Sexta',
        model: 'additionalData.tickets.friday',
        required: true,
        class: 'col-lg-12'
      },
      {
        label: 'Valor',
        model: 'price',
        class: 'col-lg-6',
        inputMask: 'money',
        inputMaskOptions: {
          prefix: 'RS',
          precision: 2,
          locale: 'en'
        }
      },
      {
        type: 'date',
        label: 'Data de Entrada',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateIn',
        required: false,
        class: 'col-lg-6'
      },
      {
        type: 'switch',
        label: 'Ingresso Sábado',
        model: 'additionalData.tickets.friday',
        required: true
      },
      {
        label: 'Price',
        model: 'price',
        class: 'col-lg-6',
        inputMask: 'money',
        inputMaskOptions: {
          prefix: 'USD',
          precision: 2,
          locale: 'en'
        }
      },
      {
        type: 'date',
        label: 'Data de Entrada',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateIn',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Detalhes',
        fields: [
          {
            type: 'text-field',
            class: 'xs12 sm12 md12 col-lg-3',
            label: 'Primeiro nome',
            model: 'firstName'
          },
          {
            type: 'text-field',
            class: 'xs12 sm12 md12 col-lg-9',
            label: 'Nome completo',
            model: 'fullName'
          },
          {
            type: 'text-field',
            class: 'xs12 sm12 md12 col-lg-3',
            label: 'CPF',
            model: 'cpf',
            readOnly: true
          },
          {
            type: 'text-field',
            class: 'xs12 sm12 md12 col-lg-6',
            label: 'Bairro',
            model: 'neighborhood'
          },
          {
            type: 'text-field',
            class: 'xs12 sm12 md12 col-lg-3',
            label: 'Celular',
            model: 'cellphone'
          },
          {
            type: 'text-field',
            class: 'xs12 sm12 md12 col-lg-3',
            label: 'Religião',
            model: 'additionalData.religiao',
            readOnly: true
          },
          {
            type: 'text-field',
            class: 'xs12 sm12 md12 col-lg-3',
            label: 'Cidade/UF',
            model: 'city'
          }
        ]
      },
      {
        name: 'Adicionais',
        fields: [
          {
            type: 'array',
            label: 'Adicionais',
            model: 'additionalData.additionals',
            arrayOf: {
              fields: [
                { type: 'text-field', label: 'Nome', model: 'nome' },
                { type: 'text-field', label: 'CPF', model: 'cpf' },
                { type: 'text-field', label: 'Celular', model: 'tel' },
                { type: 'text-field', label: 'Bairro', model: 'bairro' },
                { type: 'text-field', label: 'Cidade/UF', model: 'cidade' },
                { type: 'text-field', label: 'Religião', model: 'religiao' }
              ]
            }
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
