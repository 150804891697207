const entity = {
  name: {
    singular: 'Módulo',
    plural: 'Módulos'
  },
  collection: 'modules',
  tableHeaders: [
    { title: 'Nome', value: 'name', sortable: true },
    { title: 'code', value: 'code', sortable: true },
    { title: 'icon', value: 'icon', sortable: true },
    { title: 'route', value: 'route', sortable: true },
    { title: 'system', value: 'system', sortable: true }
  ],
  model: {
    name: '',
    code: '',
    model: '',
    icon: '',
    route: '',
    system: '',
    fields: [],
    permissions: []
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'select',
        label: 'Sistema',
        model: 'system',
        multiple: true,
        options: [
          { label: 'Base', value: 'base' },
          { label: 'CMS', value: 'cms' },
          { label: 'ERP', value: 'erp' },
          { label: 'School', value: 'sch' },
          { label: 'Profiler', value: 'prf' },
          { label: 'LoMS', value: 'loms' },
          { label: 'MLM', value: 'mlm' }
        ]
      },
      {
        type: 'text-field',
        label: 'Name',
        model: 'name',
        required: true
      },
      {
        type: 'text-field',
        label: 'Code',
        model: 'code',
        required: true
      },
      {
        type: 'text-field',
        label: 'Model',
        model: 'model',
        required: true
      },
      {
        type: 'text-field',
        label: 'Icon',
        model: 'icon',
        type: 'icon',
        required: true
      },
      {
        type: 'text-field',
        label: 'Route',
        model: 'route',
        required: true
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Fields',
        fields: [
          {
            type: 'tree',
            label: 'Schema',
            model: 'schema'
          }
        ]
      },
      {
        name: 'Permissões',
        fields: [
          {
            type: 'array',
            label: 'Permissions',
            model: 'permissions',
            required: true,
            arrayOf: {
              fields: [
                {
                  type: 'select',
                  label: 'Role',
                  model: 'role',
                  options: [
                    { label: 'Administrador', value: 'admin' },
                    { label: 'Supervisor', value: 'supervisor' },
                    { label: 'Redator', value: 'writer' }
                  ]
                },
                {
                  type: 'select',
                  label: 'Action',
                  model: 'action',
                  multiple: true,
                  options: [
                    { label: 'Create', value: 'create' },
                    { label: 'Read', value: 'read' },
                    { label: 'Update', value: 'update' },
                    { label: 'Delete', value: 'delete' }
                  ]
                },
                {
                  type: 'text-field',
                  inputType: 'text',
                  label: 'Posição',
                  model: 'position',
                  required: true
                }
              ]
            }
          }
        ]
      },
      {
        name: 'Formulário',
        fields: [
          {
            type: 'form-generator',
            label: 'Schema',
            model: 'form',
            required: false
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
