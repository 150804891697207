import crudService from '@/services/CrudService'

const entity = {
  name: {
    singular: 'Conteúdo',
    plural: 'Conteúdos'
  },
  styles: {
    gridTemplateColumns: '30% 1fr',
    gridTemplateAreas: '"main secondary"'
  },
  collection: 'contents',
  tableHeaders: [
    { title: 'Título', value: 'title', type: 'string', sortable: true},
    { title: 'Sections', value: 'sections', type: 'arrayOfParents', sortable: true},
    { title: 'Data', value: 'date', type: 'string', sortable: true},
    { title: 'Url Amigável', value: 'slug', type: 'string', sortable: true},
    { title: 'Publicado', value: 'published', type: 'boolean'}
  ],
  listFields: {
    fields: [
      'sections',
      'title',
      'slug',
      'postDate',
      'dateIn',
      'dateOut',
      'published'
    ],
    populate: ['sections']
  },
  tableSchema: [
    {
      title: 'Título',
      class: 'list-title',
      columnLayout: `
        <b class="text-capitalize">[title]</b><br>
        Publicado: [published]
        `
    },
    {
      title: 'Sections',
      class: 'list-sections',
      dataType: 'list',
      field: 'sections',
      columnLayoutPrepend: `<ul>`,
      columnLayout: `<li>[title]</li>`,
      columnLayoutAppend: `</ul>`
    },
  ],
  model: {
    sections: [],
    title: '',
    summary: '',
    fileTeaser: '',
    fileTeaserMobile: '',
    body: '',
    styles: '',
    gallery: [],
    slug: '',
    postDate: new Date(),
    dateIn: null,
    dateOut: null,
    published: true
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        type: 'date',
        label: 'Data deste Conteúdo',
        model: 'postDate',
        required: true,
        class: 'col-lg-6'
      },
      {
        type: 'select',
        label: 'Seções',
        model: 'sections',
        multiple: true,
        options: async () => await crudService('sections').getOptions(),
        class: 'xs12 sm12 md12 col-lg-12'
      },
      {
        type: 'url',
        label: 'Url amigável',
        model: 'slug',
        required: true
      },
      {
        type: 'date',
        label: 'Data de Entrada',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateIn',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Data de Saída',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateOut',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado',
        class: 'xs12 sm12 md6 col-lg-6'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Conteúdo',
        fields: [
          {
            type: 'text-field',
            label: 'Título',
            model: 'title',
            required: true
          },
          {
            type: 'text-field',
            label: 'Resumo',
            model: 'summary',
            required: false
          },
          {
            type: 'editor',
            label: 'Conteúdo',
            model: 'body',
            required: false
          },
          {
            type: 'code-editor',
            language: 'css',
            label: 'Estilos',
            model: 'styles',
            required: false
          }
        ]
      },
      {
        name: 'Imagens',
        fields: [
          {
            type: 'single-file',
            label: 'Imagem do Topo',
            model: 'fileTeaser',
            hideInput: true,
            class: 'xs12 sm12 md6 col-lg-6'
          },
          {
            type: 'single-file',
            label: 'Mobile',
            model: 'fileTeaserMobile',
            hideInput: true,
            class: 'xs12 sm12 md6 col-lg-6'
          }
        ]
      },
      {
        name: 'Galeria',
        fields: [
          {
            type: 'gallery-cards',
            label: 'Galeria de Imagens',
            model: 'gallery'
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }
}

export default entity
