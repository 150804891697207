<template>
  <div id="main-right-sidebar" class="page-right-sidebar">
    <div class="page-right-sidebar-inner">
      <div class="right-sidebar-top">
        <span class="chat-header">Chat</span>
        <a
          href="javascript:void(0)"
          class="right-sidebar-toggle right-sidebar-close"
          data-sidebar-id="main-right-sidebar"
          ><i class="icon-close"></i
        ></a>
      </div>
      <div class="right-sidebar-content">
        <!-- Tab panes -->
        <div class="chat-list">
          <span class="chat-title">Recent</span>
          <a
            href="javascript:void(0);"
            class="right-sidebar-toggle chat-item unread"
            data-sidebar-id="chat-right-sidebar"
          >
            <div class="user-avatar">
              <img src="/assets/images/avatars/avatar1.png" alt="" />
            </div>
            <div class="chat-info">
              <span class="chat-author">David</span>
              <span class="chat-text">Hello there!</span>
              <span class="chat-time">16:20</span>
            </div>
          </a>
          <a
            href="javascript:void(0);"
            class="right-sidebar-toggle chat-item unread active-user"
            data-sidebar-id="chat-right-sidebar"
          >
            <div class="user-avatar">
              <img src="/assets/images/avatars/avatar2.png" alt="" />
            </div>
            <div class="chat-info">
              <span class="chat-author">Bob</span>
              <span class="chat-text">Hello there!</span>
              <span class="chat-time">11:34</span>
            </div>
          </a>
        </div>
        <div class="chat-list">
          <span class="chat-title">Older</span>
          <a
            href="javascript:void(0);"
            class="right-sidebar-toggle chat-item"
            data-sidebar-id="chat-right-sidebar"
          >
            <div class="user-avatar">
              <img src="/assets/images/avatars/avatar3.png" alt="" />
            </div>
            <div class="chat-info">
              <span class="chat-author">Tom</span>
              <span class="chat-text">Hello there!</span>
              <span class="chat-time">2d</span>
            </div>
          </a>
          <a
            href="javascript:void(0);"
            class="right-sidebar-toggle chat-item active-user"
            data-sidebar-id="chat-right-sidebar"
          >
            <div class="user-avatar">
              <img src="/assets/images/avatars/avatar4.png" alt="" />
            </div>
            <div class="chat-info">
              <span class="chat-author">Anna</span>
              <span class="chat-text">Hello there!</span>
              <span class="chat-time">4d</span>
            </div>
          </a>
          <a
            href="javascript:void(0);"
            class="right-sidebar-toggle chat-item active-user"
            data-sidebar-id="chat-right-sidebar"
          >
            <div class="user-avatar">
              <img src="/assets/images/avatars/avatar5.png" alt="" />
            </div>
            <div class="chat-info">
              <span class="chat-author">Noah</span>
              <span class="chat-text">Hello there!</span>
              <span class="chat-time">&nbsp;</span>
            </div>
          </a>
          <a
            href="javascript:void(0);"
            class="load-more-messages"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Load More"
            >&bull;&bull;&bull;</a
          >
        </div>
      </div>
    </div>
  </div>
  <div id="chat-right-sidebar" class="page-right-sidebar">
    <div class="page-right-sidebar-inner">
      <div class="right-sidebar-top">
        <div class="chat-top-info">
          <span class="chat-name">Noah</span>
          <span class="chat-state">2h ago</span>
        </div>
        <a
          href="javascript:void(0)"
          class="right-sidebar-toggle chat-sidebar-close float-right"
          data-sidebar-id="chat-right-sidebar"
          ><i class="icon-keyboard_arrow_right"></i
        ></a>
      </div>
      <div class="right-sidebar-content">
        <div class="right-sidebar-chat slimscroll">
          <div class="chat-bubbles">
            <div class="chat-start-date">02/06/2017 5:58PM</div>
            <div class="chat-bubble them">
              <div class="chat-bubble-img-container">
                <img src="/assets/images/avatars/avatar1.png" alt="" />
              </div>
              <div class="chat-bubble-text-container">
                <span class="chat-bubble-text">Hello</span>
              </div>
            </div>
            <div class="chat-bubble me">
              <div class="chat-bubble-text-container">
                <span class="chat-bubble-text">Hello!</span>
              </div>
            </div>
            <div class="chat-start-date">03/06/2017 4:22AM</div>
            <div class="chat-bubble me">
              <div class="chat-bubble-text-container">
                <span class="chat-bubble-text">lorem</span>
              </div>
            </div>
            <div class="chat-bubble them">
              <div class="chat-bubble-img-container">
                <img src="/assets/images/avatars/avatar1.png" alt="" />
              </div>
              <div class="chat-bubble-text-container">
                <span class="chat-bubble-text">ipsum dolor sit amet</span>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-write">
          <form class="form-horizontal" action="javascript:void(0);">
            <input
              type="text"
              class="form-control"
              placeholder="Say something"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
