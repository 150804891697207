import crudService from '@/services/CrudService'

const entity = {
  name: {
    singular: 'Member',
    plural: 'Members'
  },
  collection: 'members',
  tableHeaders: [
    { title: 'Name', value: 'name' },
    { title: 'Phone', value: 'cellphone' },
    { title: 'Email', value: 'email' }
  ],
  model: {
    name: '',
    email: '',
    document: '',
    cellphone: '',
    landline: '',
    address: '',
    address2: '',
    neighborhood: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    status: 'pending',
    qualification: '',
    sponsor: null,
    sponsored: []
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        label: 'Sponsor',
        model: 'sponsor',
        class: 'col-lg-6',
        type: 'select',
        options: async () => await crudService('members').getOptions()
      },
      {
        label: 'Member Type',
        model: 'memberType',
        class: 'col-lg-6',
        type: 'select',
        options: ['Member', 'Customer']
      },
      {
        label: 'Level',
        model: 'qualification',
        class: 'col-lg-6',
        type: 'select',
        options: ['Representative', 'Manager', 'Director', 'ExecutiveDirector']
      },
      { label: 'Name', model: 'name', class: 'col-lg-12', required: true },
      { label: 'Email', model: 'email', class: 'col-lg-12', required: true },
      { label: 'Celphone', model: 'cellphone', class: 'col-lg-6' },
      { label: 'Landline', model: 'landline', class: 'col-lg-6' },
      {
        label: 'Document',
        model: 'document',
        class: 'col-lg-6',
        required: true
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Address',
        fields: [
          {
            label: 'Address',
            model: 'address',
            class: 'col-lg-12',
            required: true
          },
          { label: 'Address 2', model: 'address2', class: 'col-lg-12' },
          { label: 'Neighboorhood', model: 'neighborhood', class: 'col-lg-12' },
          { label: 'City', model: 'city', class: 'col-lg-8', required: true },
          { label: 'State', model: 'state', class: 'col-lg-4', required: true },
          {
            label: 'Country',
            model: 'country',
            class: 'col-lg-8',
            required: true
          },
          { label: 'Zip', model: 'zip', class: 'col-lg-4', required: true }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
