const entity = {
  name: {
    singular: 'Category',
    plural: 'Categories'
  },
  collection: 'categories',
  tableHeaders: [
    { title: 'Name', value: 'name' },
    { title: 'Slug', value: 'slug' },
    { title: 'Images', value: 'images' }
  ],
  model: {
    name: '',
    slug: '',
    description: '',
    products: []
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      { label: 'Name', model: 'name', class: 'col-lg-12' },
      { label: 'Slug', model: 'slug', class: 'col-lg-12' }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Products',
        groupType: 'list',
        entity: 'products',
        itemsPerPage: 10,
        fields: ['name', 'slug', 'price'],
        tableHeaders: [
          { title: 'Name', value: 'name' },
          { title: 'Slug', value: 'slug' },
          { title: 'Price', value: 'price' }
        ],
        relatedCollection: 'categories',
        relatedCollectionIdField: 'categories'
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
