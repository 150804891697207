import categoriesRoutes from './categories'
import membersRoutes from './members'
import ordersRoutes from './orders'
import commissionsRoutes from './commissions'
import productsRoutes from './products'
import receivablesRoutes from './receivables'
import reportsRoutes from './reports'

export default [
  ...categoriesRoutes,
  ...membersRoutes,
  ...ordersRoutes,
  ...commissionsRoutes,
  ...productsRoutes,
  ...receivablesRoutes,
  ...reportsRoutes
]
