<template>
  <div v-if="isAuthenticated" class="app__container page-container">
    <MainSidebar />
    <div class="page-content">
      <SecondarySidebar />
      <MainHeader />
      <div class="page-inner">
        <div id="main-wrapper">
          <RouterView v-slot="{ Component }" :key="$route.path">
            <transition name="slide" mode="out-in">
              <component
                :is="Component"
                :key="$route.path"
                :class="`component__${$route.name}`"
              />
            </transition>
          </RouterView>
        </div>
        <div class="page-footer">
          <p>{{ new Date().getFullYear() }} &copy; Maestro</p>
        </div>
      </div>
      <RightSidebar />
    </div>
  </div>

  <div v-else class="page-container">
    <AuthView>
      <RouterView :key="$route.path" />
    </AuthView>
  </div>

  <m-notification></m-notification>
</template>

<script setup>
import { RouterView } from 'vue-router'
import MainSidebar from './components/util/MainSidebar.vue'
import SecondarySidebar from './components/util/SecondarySidebar.vue'
import MainHeader from './components/util/MainHeader.vue'
import RightSidebar from './components/util/RightSidebar.vue'
import AuthView from './views/auth/AuthView.vue'
import mNotification from './components/m-components/m-notification.vue'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'

const store = useStore()
const isAuthenticated = computed(
  () => store.getters['authentication/isAuthenticated']
)

onMounted(() => {
  if (window.innerWidth < 640) {
    document.body.classList.add('is-mobile')
    document.body.classList.add('collapsed-sidebar')
    document.body.classList.add('hidden-sidebar')
  }
})
</script>

<style lang="scss">
#maestro {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    color: #35495e;
  }

  p,
  li,
  label,
  a,
  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  input,
  textarea,
  select {
    font-family: 'Roboto Mono', monospace;
    font-size: 13px;
    color: #35495e;
  }

  input[type='text'] {
    min-height: 40px;
  }

  .nav-tabs {
    margin-bottom: 0px;
    background-color: #d1dade;
    padding: 0;

    li {
      margin-bottom: -3px;
      padding-left: 0;

      span {
        border-radius: 0;
        border: none;
        padding: 15px 20px;
        color: #8e9aa2;
        min-width: 70px;
        display: block;
        cursor: pointer;
        transition: 0.3s all;
        font-size: 14px;
        margin-right: 0px;

        &:hover,
        &:focus {
          color: #505458;
        }
      }

      &.active {
        span,
        span:hover,
        span:focus {
          background-color: white;
          color: #505458;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .full-max-height {
      overflow: auto;
      height: calc(100vh - 240px);
    }

    .nav.nav-tabs ~ .full-max-height {
      overflow: auto;
      height: calc(100vh - 289px);
    }
  }
}

.app__container {
  .page-content {
    z-index: 1;
  }
}

body {
  background-color: white;
}

* {
  font-family: Roboto, sans-serif;
  font-weight: lighter;
}

.page-content {
  min-height: 100vh;
}

.page-inner {
  position: relative;
  min-height: calc(100vh - 60px);
}

.page-footer {
  position: absolute;
  bottom: 0;
}

/* we will explain what these classes do next! */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s ease;
}

.slide-enter-from {
  opacity: 0;
  transform: translateY(-5%);
}

.slide-leave-to {
  opacity: 0;
  transform: translateY(15%);
}
</style>
