const entity = {
  name: {
    singular: 'Usuário',
    plural: 'Usuários'
  },
  collection: 'users',
  tableHeaders: [
    { title: 'Nome', value: 'name' },
    { title: 'Email', value: 'email' },
    { title: 'Celular', value: 'cellphone' },
    { title: 'Permissão', value: 'role' }
  ],
  model: {
    fileAvatar: '',
    name: '',
    email: '',
    cellphone: '',
    role: 'writer',
    status: 'denied',
    account: ''
  },
  schema: {
    groups: [
      {
        name: 'Dados',
        fields: [
          {
            type: 'single-file',
            label: 'Avatar',
            model: 'fileAvatar',
            hideInput: true,
            class: 'col-lg-12'
          },
          {
            type: 'text-field',
            label: 'Nome',
            model: 'name',
            required: true,
            class: 'col-lg-12'
          },
          {
            type: 'text-field',
            label: 'Email',
            model: 'email',
            required: true,
            class: 'col-lg-12'
          },
          {
            type: 'text-field',
            inputType: 'text',
            label: 'Celular',
            model: 'cellphone',
            required: false,
            class: 'col-lg-4'
          },
          {
            type: 'select',
            label: 'Role',
            model: 'role',
            options: [
              { label: 'Administrador', value: 'admin' },
              { label: 'Supervisor', value: 'supervisor' },
              { label: 'Redator', value: 'writer' }
            ],
            class: 'col-lg-4'
          },
          {
            type: 'switch',
            label: 'Status',
            model: 'status',
            textOn: 'Aprovado',
            textOff: 'Inativo',
            valueOn: 'approved',
            valueOff: 'denied',
            class: 'col-lg-4'
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
