export default [
  {
    name: 'forbidden',
    path: '/forbidden',
    component: () =>
      import(
        /* webpackChunkName: "forbidden" */ '@/components/util/Forbidden.vue'
      )
  }
]
