import crudService from '@/services/CrudService'
import usersEntity from './users'

const entity = {
  name: {
    singular: 'Conta',
    plural: 'Contas'
  },
  collection: 'accounts',
  tableHeaders: [
    { title: 'Nome', value: 'name' }
  ],
  model: {
    name: '',
    active: false,
    token: '',
    accountId: '',
    modules: [],
    settings: {},
    dateIn: new Date(),
    dateOut: ''
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'text-field',
        label: 'Nome',
        model: 'name',
        required: true
      },
      {
        type: 'text-field',
        label: 'Código',
        model: 'code',
        required: true
      },
      {
        type: 'token',
        label: 'Token',
        model: 'token'
      },
      {
        type: 'date',
        label: 'Início',
        model: 'dateIn',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Término',
        model: 'dateOut',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'switch',
        label: 'Active',
        model: 'active'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Settings',
        fields: [
          {
            label: 'Módulos',
            model: 'modules',
            type: 'select',
            options: async () => await crudService('modules').getOptions(),
            multiple: true,
            required: true,
            class: 'col-md-12'
          },
          {
            label: 'Dashboards',
            type: 'array',
            model: 'settings.dashboards',
            arrayOf: {
              fields: [
                {
                  label: 'Dashboard',
                  model: 'dashboard',
                  type: 'select',
                  options: async () =>
                    await crudService('dashboards').getOptions(),
                  multiple: false,
                  required: true,
                  class: 'col-md-6'
                },
                {
                  type: 'select',
                  label: 'Role',
                  model: 'role',
                  options: [
                    { label: 'Administrador', value: 'admin' },
                    { label: 'Supervisor', value: 'supervisor' },
                    { label: 'Redator', value: 'writer' },
                    { label: 'All', value: 'all' }
                  ],
                  class: 'col-lg-4'
                }
              ],
            },
            class: 'col-md-12'
          }
        ]
      },
      {
        name: 'Usuários',
        groupType: 'list',
        entity: usersEntity,
        relatedCollection: 'accounts',
        relatedCollectionIdField: 'accountId',
        editOnDrawer: true,
        addButton: true
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
