import entity from '../../../models/base/users'

export default [
  {
    name: 'users',
    path: '/users',
    meta: {
      entity,
      requiresPermissionTo: 'users',
      crud: 'list'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    name: 'users_new',
    path: '/users/new',
    meta: {
      entity,
      requiresPermissionTo: 'users',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    name: 'users_edit',
    path: '/users/:id',
    meta: {
      entity,
      requiresPermissionTo: 'users',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
