<template>
  <Transition name="fade">
    <div v-if="messages.length" role="alert" class="m-notification__container">
      <TransitionGroup tag="ul" name="fade" class="m-notification__list">
        <li
          v-for="(item, index) in messages"
          :key="'messageId' + index"
          :class="`alert alert-dismissible alert-${item.type}`"
        >
          <h6 v-if="item.title" class="alert-heading">
            {{ item.title }}
          </h6>
          <p v-if="item.text" v-html="item.text" />
          <button type="button" class="close" @click="removeMessage(item)">
            <span aria-hidden="true">&times;</span>
          </button>
        </li>
      </TransitionGroup>
    </div>
  </Transition>
</template>

<script setup>
// TODO: Fix the order when removing one toast.

import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const store = useStore()

const messages = computed(() => store.getters['messages/messages'])

const props = defineProps({
  title: {
    required: false,
    type: String,
    default: null
  },
  text: {
    required: true,
    type: String
  },
  type: {
    required: false,
    type: String,
    default: 'primary',
    validator: (type) =>
      [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark'
      ].includes(type)
  }
})

const removeMessage = function (message) {
  store.dispatch('messages/removeMessage', message.id)
}
</script>

<style lang="scss">
.m-notification__container {
  width: 400px;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 100;

  ul {
    padding-left: 0;
    list-style: none;
  }

  p {
    margin-bottom: 0;
  }

  .close {
    box-sizing: border-box;
    border-radius: 0;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .5;
  }

  .alert {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, .07);
    padding: 12px 15px;
    font-size: 13px;
  }

  .alert-dismissible .close {
    padding: 12px 15px;
  }

  .alert-heading {
    font-size: 14px;
    font-weight: bold;
    margin: 5px 0;
  }

  .alert-primary {
    color: #3f037a;
    background: #d8bff2;
  }

  .alert-primary.outline-alert {
    background: transparent;
    border: 2px solid #9b5edb;
    font-weight: bold;
    color: #9b5edb;
  }

  .alert-success {
    color: #155724;
    background-color: #D4EDDA;
  }

  .alert-success.outline-alert {
    background: transparent;
    border: 2px solid #16cc65;
    font-weight: bold;
    color: #16cc65;
  }

  .alert-info {
    color: #2f73c8;
    background-color: #d9e7f9;
  }

  .alert-info.outline-alert {
    background: transparent;
    border: 2px solid #4797ff;
    font-weight: bold;
    color: #4797ff;
  }

  .alert-warning {
    color: #94780a;
    background-color: #f9e69b;
  }

  .alert-warning.outline-alert {
    background: transparent;
    border: 2px solid #edcc3d;
    font-weight: bold;
    color: #edcc3d;
  }

  .alert-danger {
    color: #d83945;
    background-color: #fbdbde;
  }

  .alert-danger.outline-alert {
    background: transparent;
    border: 2px solid #ef5160;
    font-weight: bold;
    color: #ef5160;
  }

  .alert-light.outline-alert {
    background: transparent;
    border: 2px solid #b5b5b5;
    font-weight: bold;
    color: #b5b5b5;
  }

  .alert-dark.outline-alert {
    background: transparent;
    border: 2px solid #666b7a;
    font-weight: bold;
    color: #666b7a;
  }

  .alert-secondary.outline-alert {
    background: transparent;
    border: 2px solid #b0aabf;
    font-weight: bold;
    color: #b0aabf;
  }

  .alert-success .alert-link {
    color: #218892;
  }

  .alert-info .alert-link {
    color: #2f73c8;
  }

  .alert-warning .alert-link {
    color: #94780a;
  }

  .alert-danger .alert-link {
    color: #d83945;
  }
}


/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}
</style>
