import crudService from '@/services/CrudService'
import orderItemsEntity from './ordersItems'

const ORDER_STATUS = ['PENDING', 'PROCESSED', 'DELIVERED', 'SHIPPED']
const ORDER_ACTIONS = [{
  label: 'Close and apply commissions', value: 'CLOSE_AND_APPLY_COMMISSIONS'
}]

const entity = {
  name: {
    singular: 'Order',
    plural: 'Orders'
  },
  collection: 'orders',
  tableHeaders: [
    { title: 'Member', value: 'member.name' },
    { title: 'Date', value: 'date' },
    { title: 'Status', value: 'status' },
    { title: 'Items', value: 'orderItems', type: 'list' },
    { title: 'Total', value: 'total' }
  ],
  tableSchema: [
    {
      title: 'Member',
      class: 'list-member',
      columnLayout: `[member.name]`
    },
    {
      title: 'Date',
      class: 'list-date',
      columnLayout: `[date]`
    },
    {
      title: 'Status',
      class: 'list-status',
      columnLayout: `[status]`,
    },
    {
      title: 'Total',
      class: 'list-total',
      columnLayout: `
        [total]
      `
    }
  ],
  model: {
    date: '',
    status: '',
    member: '',
    orderItems: [],
    notes: []
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        label: 'Member',
        model: 'member',
        class: 'col-lg-12',
        type: 'select',
        options: async () => await crudService('members').getOptions()
      },
      {
        label: 'Date',
        type: 'date',
        model: 'date',
        class: 'col-lg-12'
      },
      {
        label: 'Status',
        model: 'status',
        class: 'col-lg-12',
        type: 'select',
        options: ORDER_STATUS
      },
      {
        label: 'Actions',
        model: 'actions',
        class: 'col-lg-12',
        type: 'select',
        options: ORDER_ACTIONS
      }
    ]
  },
  listFields: {
    fields: ['date', 'status', 'member', 'orderItems'],
    populate: ['member', 'user', 'orderItems']
  },
  schema: {
    groups: [
      {
        name: 'OrderItems',
        groupType: 'list',
        model: 'orderItems',
        entity: orderItemsEntity,
        relatedCollection: 'orders',
        relatedCollectionIdField: 'order',
        editOnDrawer: true
      },
      {
        name: 'Notes',
        fields: [
          {
            label: 'Notes',
            type: 'array',
            model: 'notes',
            arrayOf: {
              fields: [
                {
                  label: 'Title',
                  model: 'title',
                  class: 'col-lg-8'
                },
                {
                  label: 'Date',
                  model: 'date',
                  readOnly: true,
                  class: 'col-lg-4'
                },
                {
                  label: 'Note',
                  model: 'textarea',
                  class: 'col-lg-12'
                },
                {
                  label: 'Position',
                  model: 'position',
                  required: true
                }
              ]
            }
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
