const entity = {
  name: {
    singular: 'Mailing',
    plural: 'Mailings'
  },
  collection: 'mailings',
  tableHeaders: [
    { title: 'Título', value: 'title' },
    { title: 'Descrição', value: 'description' }
  ],
  model: {
    title: null,
    collection: '',
    description: '',
    files: []
  },
  schema: {
    groups: [
      {
        name: 'Detalhes',
        fields: [
          {
            type: 'text-field',
            label: 'Título',
            model: 'title',
            required: true
          },
          {
            type: 'text-field',
            label: 'Collection',
            model: 'collection',
            required: true
          },
          {
            type: 'text-field',
            label: 'Descrição',
            model: 'description',
            required: true
          },
          {
            type: 'single-file',
            label: 'Arquivos',
            model: 'files',
            required: true,
            allowedFileTypes: 'text/csv',
            allowedExtensions: 'csv'
          }
        ]
      },
      {
        name: 'Collection',
        list: 'collection'
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
