const entity = {
  name: {
    singular: 'Campanha',
    plural: 'Campanhas'
  },
  collection: 'campaigns',
  tableHeaders: [
    { title: 'Nome', value: 'name' }
  ],
  model: {
    name: '',
    mailing: '',
    text: '',
    startDate: '',
    status: 'pending',
    accountId: ''
  },
  schema: {
    groups: [
      {
        name: 'Detalhes',
        fields: [
          {
            type: 'text-field',
            label: 'Nome',
            model: 'name',
            required: true
          },
          {
            type: 'select',
            label: 'Mailing',
            model: 'mailing',
            required: true
          },
          {
            type: 'textarea',
            label: 'Mensagem',
            model: 'text',
            required: true
          },
          {
            type: 'date',
            label: 'Data de Início',
            model: 'date',
            required: true
          }
        ]
        // },
        // {
        //   name: 'Mailing',
        //   groupType: 'list',
        //   list: 'contacts',
        //   itemsPerPage: 50,
        //   fields: ['name', 'email', 'cellphone', 'status'],
        //   relatedCollection: 'campaigns'
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
