import entity from '../../../models/forms/forms'

export default [
  {
    path: '/forms',
    meta: {
      entity,
      requiresPermissionTo: 'forms',
      crud: 'list'
    },
    component: () =>
      import(/* webpackChunkName: "forms" */ '@/views/dynamic/DynamicIndex.vue')
  },
  {
    path: '/forms/new',
    meta: {
      entity,
      requiresPermissionTo: 'forms',
      crud: 'form'
    },
    component: () =>
      import(/* webpackChunkName: "forms" */ '@/views/dynamic/DynamicIndex.vue')
  },
  {
    path: '/forms/:id',
    meta: {
      entity,
      requiresPermissionTo: 'forms',
      crud: 'form'
    },
    component: () =>
      import(/* webpackChunkName: "forms" */ '@/views/dynamic/DynamicIndex.vue')
  }
]
