const entity = {
  name: {
    singular: 'Pai',
    plural: 'Pais'
  },
  collection: 'parents',
  tableHeaders: [
    { title: 'Nome', value: 'name' },
    { title: 'CPF', value: 'document' },
    { title: 'Telefone', value: 'landline' },
    { title: 'Celular', value: 'cellphone' },
    { title: 'Email', value: 'email' }
  ],
  model: {
    name: '',
    email: '',
    document: '',
    cellphone: '',
    landline: '',
    zipcode: '',
    city: '',
    state: '',
    address: '',
    addressNumber: '',
    address2: '',
    neighborhood: '',
    country: '',
    buyDate: ''
  },
  config: {
    name: 'Config',
    fields: []
  },
  schema: {
    groups: [
      {
        name: 'Dados pessoais',
        fields: [
          {
            type: 'text-field',
            label: 'Nome',
            model: 'name',
            class: 'xs12 sm12 md12 col-lg-12'
          },
          {
            type: 'text-field',
            label: 'CPF',
            model: 'document',
            class: 'xs12 sm12 md6 col-lg-6',
            inputMask: '###.###.###-##',
            outputMask: '###########'
          },
          {
            type: 'text-field',
            label: 'Email',
            model: 'email',
            class: 'xs12 sm12 md6 col-lg-6'
          },
          {
            type: 'text-field',
            label: 'Telefone',
            model: 'landline',
            class: 'xs12 sm12 md6 col-lg-6',
            inputMask: '(##) ####-####',
            outputMask: '###########'
          },
          {
            type: 'text-field',
            label: 'Celular',
            model: 'cellphone',
            class: 'xs12 sm12 md6 col-lg-6',
            inputMask: '(##) #####-####',
            outputMask: '###########'
          },
          {
            type: 'text-field',
            label: 'CEP',
            model: 'zipcode',
            class: 'xs12 sm12 md3 col-lg-3',
            inputMask: '#####-###',
            outputMask: '########'
          },
          {
            type: 'text-field',
            label: 'Endereço',
            model: 'address',
            class: 'xs12 sm12 md8 col-lg-8'
          },
          {
            type: 'text-field',
            label: 'Número',
            model: 'number',
            class: 'xs12 sm12 md1 col-lg-1'
          },
          {
            type: 'text-field',
            label: 'Complemento',
            model: 'address2',
            class: 'xs12 sm12 md3 col-lg-3'
          },
          {
            type: 'text-field',
            label: 'Bairro',
            model: 'neighborhood',
            class: 'xs12 sm12 md3 col-lg-3'
          },
          {
            type: 'text-field',
            label: 'Cidade',
            model: 'city',
            class: 'xs12 sm12 md5 col-lg-5'
          },
          {
            type: 'text-field',
            label: 'Estado',
            model: 'state',
            class: 'xs12 sm12 md1 col-lg-1'
          }
        ]
      },
      {
        name: 'Alunos',
        groupType: 'list',
        entity: 'students',
        itemsPerPage: 50,
        fields: [
          'picture',
          'name',
          'studentId',
          'landline',
          'cellphone',
          'email'
        ],
        relatedCollection: 'parents',
        relatedCollectionIdField: 'students',
        addButton: false,
        tableHeaders: [
          { title: 'Foto', value: 'picture', type: 'avatar' },
          { title: 'Nome', value: 'name' },
          { title: 'RA', value: 'studentId' },
          { title: 'Telefone', value: 'landline' },
          { title: 'Celular', value: 'cellphone' },
          { title: 'Email', value: 'email' }
        ],
        editOnDrawer: false
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
