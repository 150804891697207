import crudService from '@/services/CrudService'

const entity = {
  name: {
    singular: 'Product',
    plural: 'Products'
  },
  collection: 'products',
  tableHeaders: [
    { title: 'Name', value: 'name' },
    { title: 'Slug', value: 'slug' },
    { title: 'Retail Price', value: 'price' },
    { title: 'Members Price', value: 'wholesalePrice' },
    { title: 'PVs', value: 'points' }
  ],
  model: {
    name: '',
    slug: '',
    price: 0,
    wholesale_price: 0,
    points: 0,
    description: '',
    categories: [],
    images: []
  },
  config: {
    name: 'Product',
    fields: [
      {
        label: 'Categories',
        model: 'categories',
        type: 'select',
        options: async () => await crudService('categories').getOptions(),
        multiple: true,
        required: true,
        class: 'col-md-12'
      },
      { label: 'Name', model: 'name', class: 'col-lg-12' },
      { label: 'Slug', model: 'slug', class: 'col-lg-12' },
      { label: 'Retail Price', model: 'price', class: 'col-lg-12' },
      { label: 'Members Price', model: 'wholesalePrice', class: 'col-lg-12' },
      { label: 'PVs', model: 'points', class: 'col-lg-12' },
      { label: 'Summary', model: 'summary', class: 'col-lg-12' }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Description',
        fields: [
          {
            type: 'editor',
            label: 'Description',
            model: 'description'
          }
        ]
      },
      {
        name: 'Images',
        fields: [
          {
            type: 'upload',
            label: 'Images',
            model: 'images'
          }
        ]
      }
    ]
  }
}

export default entity
