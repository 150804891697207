import { localPersistence } from '@/services/LocalPersistenceService'
import axios from 'axios'

const crudService = (collection, data) => {
  const token = localPersistence.get('mstrToken')
  const baseUrl = process.env.VUE_APP_ENV_API

  axios.defaults.baseURL = process.env.VUE_APP_ENV_API
  axios.defaults.headers['Authorization'] = token

  const plainify = (obj) => JSON.parse(JSON.stringify(obj))

  const getList = async (data = {}) => {
    let { fields, page, pageSize, populate, sort } = data

    if (!page) page = 1
    if (!pageSize) pageSize = 10

    let url = `${collection}?fullTitle=true&page=${page}&limit=${pageSize}`

    if (fields && Array.isArray(fields)) {
      fields = plainify(fields).join(',')
      url += `&fields=${fields}`
    }

    if (populate && Array.isArray(populate)) {
      populate = plainify(populate).join(',')
      url += `&populate=${populate}`
    }

    if (sort) url += `&sort=${sort.field}|${sort.order}`

    try {
      const response = await axios(url)
      if (response) return response.data
      return false
    } catch (error) {
      throw new Error(error)
    }
  }

  const getRelatedContent = async (data) => {
    let { relatedField, relatedId, fields, populate } = data

    console.log(relatedField, relatedId, fields, populate)

    let url = `/${collection}?relatedField=${relatedField}&relatedId=${relatedId}&fields=${fields}`

    if (populate && Array.isArray(populate)) {
      populate = plainify(populate).join(',')
      url += `&populate=${populate}`
    }

    console.log(url)

    try {
      const response = await axios(url)
      if (response) return response.data
      return false
    } catch (error) {
      throw new Error(error)
    }
  }

  const getOptions = async (config) => {
    const results = await getList({
      pageSize: 'all',
      sort: {
        field: 'name',
        order: 1
      }
    })

    if (results?.data) {
      const data = results?.data?.records?.map((item) => {
        const obj = {
          label: config ? item[config.label] : item.name || item.title,
          value: config ? item[config.value] : item._id
        }

        console.log('children', !config?.children)

        if (!config?.children) return obj

        obj[config.children.field] = item[config.children.field].map(
          (child) => {
            const childObj = {
              label: child[config.children.label] || child.name || child.title,
              value: child[config.children.value] || child._id
            }
            return childObj
          }
        )

        return obj
      })

      if (config?.returnRawList) {
        return { options: data, raw: results.data.records }
      }

      return data
    }

    return ['No options.']
  }

  const createItem = async () => {
    try {
      const response = await axios.post(collection, data)

      if (response.data.status === 'success') {
        const savedData = await response.data

        response.new = {
          id: response.id,
          ...savedData
        }
        return response.data
      }

      return response.data
    } catch (error) {
      return error
    }
  }

  const getItemById = async () => {
    let url = `${baseUrl}${collection}/${data.id}?v=1`

    if (data.populate) url = url + '&populate=' + data.populate
    if (data.fields) url = url + '&fields=' + data.fields

    const item = await axios(url)
    return item
  }

  const deleteItem = async () => {
    const itemId = data.id
    const response = await axios.delete(collection + '/' + itemId)
    return response
  }

  const updateItem = async () => {
    const itemId = data.id || data._id

    try {
      const response = await axios.patch(`${collection}/${itemId}`, data)

      if (response.data.status === 'success') {
        const savedData = await response.data

        response.updated = {
          id: response.id,
          ...savedData
        }
        return response.data
      }

      return response.data
    } catch (error) {
      return error
    }
  }

  // const uploadFile = async (file, field) => {
  //   if (!file) return

  //   const dir = `/Maestro/${accountId}/${collection}/`
  //   const ref = firebaseStorage.ref().child(dir)

  //   file = file.split(',')[1]

  //   let entityName = data.name || data.title || collection
  //   entityName = slugify(entityName, { lower: true })

  //   const fileName = `${(+new Date())}-${entityName}-${field}.jpg`
  //   const task = ref.child(fileName).putString(file, 'base64')
  //   const response = await task

  //   const url = response.ref.getDownloadURL()

  //   return url
  // }

  const importPDF = async (url) => {
    await axios('/importers/pdf?url=' + url)
  }

  const importFile = async (url) => {
    await axios('/importers/all?url=' + url)
  }

  const getListOfLoads = async (data) => {
    const response = await axios(
      `/importers/scraper/${data.carrierId}/${data.loadBoardId}`
    )
    return response?.data
  }

  const importLoads = async (data) => {
    if (!data.loads) return false
    const response = await axios(
      `/importers/scraper/${data.carrierId}/${data.loadBoardId}/items?loads=${data.loads}`
    )
    return response?.data
  }

  const getHeadlessEntityAndList = async () => {
    const response = await axios(`/headless/${collection}`)
    return response.data
  }

  return {
    getList,
    createItem,
    getItemById,
    updateItem,
    deleteItem,
    getRelatedContent,
    importPDF,
    getOptions,
    importFile,
    getListOfLoads,
    importLoads,
    getHeadlessEntityAndList
  }
}

export default crudService
