const entity = {
  name: {
    singular: 'Pesquisa',
    plural: 'Pesquisas'
  },
  collection: 'forms',
  tableHeaders: [
    { title: 'Título', value: 'title' },
    { title: 'Início', value: 'initialDate' },
    { title: 'Término', value: 'endDate' }
  ],
  model: {
    logotype: '',
    title: null,
    subtitle: null,
    description: null,
    backgroundColor: '',
    isRequired: true,
    slug: '',
    initialDate: '',
    endDate: '',
    mailing: '',
    questions: [],
    nextStep: 'next'
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'text-field',
        label: 'Título',
        model: 'title',
        required: true,
        class: 'xs6'
      },
      {
        type: 'text-field',
        label: 'Subtítulo',
        model: 'subtitle',
        required: false,
        class: 'xs6'
      },
      {
        type: 'text-field',
        label: 'Descrição',
        model: 'description',
        required: false,
        class: 'xs12'
      },
      {
        type: 'text-field',
        label: 'Url Amigável',
        model: 'slug',
        required: true,
        class: 'xs4'
      },
      {
        type: 'text-field',
        label: 'Cor',
        model: 'backgroundColor',
        required: true,
        class: 'xs2'
      },
      {
        type: 'date',
        label: 'Início',
        model: 'initialDate',
        required: true,
        class: 'xs3'
      },
      {
        type: 'date',
        inputType: 'text',
        label: 'Término',
        model: 'endDate',
        required: true,
        class: 'xs3'
      },
      {
        type: 'file',
        label: 'Arquivos',
        model: 'logotype',
        required: true
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Perguntas',
        fields: [
          {
            label: 'Perguntas',
            model: 'questions',
            type: 'array',
            arrayOf: {
              fields: [
                {
                  label: 'Pergunta',
                  model: 'question',
                  required: true,
                  class: 'xs12'
                },
                {
                  type: 'select',
                  label: 'Tipo da Resposta',
                  model: 'answerType',
                  required: true,
                  options: [
                    { value: 'text', label: 'Texto' },
                    { value: 'choice', label: 'Alternativa' },
                    { value: 'multiple', label: 'Múltipla escolha' }
                  ],
                  class: 'xs3'
                },
                {
                  type: 'select',
                  label: 'Próximo passo',
                  model: 'nextStep',
                  required: true,
                  options: [
                    { value: 'next', label: 'Próxima pergunta' },
                    { value: 'submit', label: 'Submeter formulário' },
                    { value: 'depends', label: 'Depende da resposta' }
                  ],
                  class: 'xs3'
                },
                {
                  label: 'Posição',
                  model: 'position',
                  required: true,
                  class: 'xs1'
                },
                {
                  type: 'switch',
                  label: 'Obrigatório',
                  model: 'isRequired',
                  valueOn: true,
                  valueOff: false,
                  class: 'xs2'
                },
                {
                  type: 'array',
                  label: 'Respostas',
                  model: 'answers',
                  required: true,
                  condition: (data) =>
                    data['answerType'] === 'multiple' ||
                    data['answerType'] === 'choice',
                  arrayOf: {
                    fields: [
                      {
                        label: 'Resposta',
                        model: 'answer',
                        required: true
                      },
                      {
                        type: 'select',
                        label: 'Ir para',
                        model: 'nextQuestion',
                        required: true,
                        options: [
                          { value: 'next', label: 'Próxima pergunta' },
                          { value: 'submit', label: 'Submeter formulário' }
                        ],
                        class: 'xs3'
                      }
                    ]
                  },
                  class: 'xs12'
                }
              ]
            }
          }
        ]
      },
      {
        name: 'Resultados',
        list: 'results'
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
