import entity from '../../../models/mlm/products'

export default [
  {
    path: '/products',
    meta: {
      entity,
      requiresPermissionTo: 'products',
      crud: 'list'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/products/new',
    meta: {
      entity,
      requiresPermissionTo: 'products',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/products/:id',
    meta: {
      entity,
      requiresPermissionTo: 'products',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
