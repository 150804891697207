import entity from '../../../models/base/accounts'

export default [
  {
    path: '/accounts',
    meta: {
      entity,
      requiresPermissionTo: 'accounts',
      crud: 'list'
    },
    component: () =>
      import(/* webpackChunkName: 'accounts' */ '@/views/dynamic/DynamicIndex')
  },
  {
    path: '/accounts/new',
    meta: {
      entity,
      requiresPermissionTo: 'accounts',
      crud: 'form'
    },
    component: () =>
      import(/* webpackChunkName: 'accounts' */ '@/views/dynamic/DynamicIndex')
  },
  {
    path: '/accounts/:id',
    meta: {
      entity,
      requiresPermissionTo: 'accounts',
      crud: 'form'
    },
    component: () =>
      import(/* webpackChunkName: 'accounts' */ '@/views/dynamic/DynamicIndex')
  }
]
