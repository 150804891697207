const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
]

const pickupDeliveryStatus = ['new', 'Pending', 'Confirmed', 'Done']

const paymentStatusOptions = ['Pending', 'Scheduled', 'Done']

const taskStatus = [
  { label: 'Pending', value: 'pending' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Done', value: 'done' }
]

const loadStatus = [
  { label: 'Preparation', value: '6327a996b39d9daa1157abc5' },
  { label: 'Ready for Pickup', value: '6327a996b39d9daa1157abc6' },
  { label: 'In Transit', value: '6327a996b39d9daa1157abc7' },
  { label: 'Delivered', value: '6327a996b39d9daa1157abc8' },
  { label: 'Done', value: '6327a996b39d9daa1157abc9' }
]

const businessType = [
  { value: 'DEALER', label: 'Dealer' },
  { value: 'PRIVATE', label: 'Private' },
  { value: 'AUCTION', label: 'Auction' },
  { value: 'REPO_YARD', label: 'Repo Yard' },
  { value: 'PORT', label: 'Port' },
  { value: 'BUSINESS', label: 'Business' }
]

const paymentTerms = [
  { value: '5_days', label: '5 days' },
  { value: '7_days', label: '7 days' },
  { value: '10_days', label: '10 days' },
  { value: '15_days', label: '15 days' },
  { value: '20_days', label: '20 days' },
  { value: '30_days', label: '30 days' },
  { value: '40_days', label: '40 days' },
  { value: '45_days', label: '45 days' },
  { value: 'ach', label: 'Ach' },
  { value: 'cash_on_pickup', label: 'Cash on pickup' },
  { value: 'check_on_pickup', label: 'Check on pickup' },
  { value: 'cash_on_delivery', label: 'Cash on delivery' },
  { value: 'check_on_delivery', label: 'Check on delivery' },
  { value: 'comchek', label: 'Comchek' },
  { value: 'quick_pay', label: 'Quick pay' },
  { value: 'other', label: 'Other' }
]

const paymentMethods = [
  { value: 'cash', label: 'cash' },
  { value: 'check', label: 'check' },
  { value: 'cashiers_check', label: 'cashiers_check' },
  { value: 'money_order', label: 'money_order' },
  { value: 'comchek', label: 'comchek' },
  { value: 'credit_card', label: 'credit_card' },
  { value: 'direct_deposit', label: 'direct_deposit' },
  { value: 'other', label: 'other' }
]

export {
  states,
  pickupDeliveryStatus,
  paymentStatusOptions,
  loadStatus,
  businessType,
  paymentTerms,
  paymentMethods,
  taskStatus
}
