const overlaysStore = {
  namespaced: true,
  state: {
    rightSidebar: false,
    leftSidebar: false,
    component: false,
    modal: {
      title: '',
      active: false
    }
  },
  mutations: {
    OPEN_MODAL: (state, title) => {
      console.log(state)
      state.modal.title = title
      state.modal.active = true
    }
  },
  actions: {
    openModal: ({ commit }, title) => {
      commit('OPEN_MODAL', title)
    }
  },
  getters: {
    modalActive: (state) => state.modal.active,
    modalTitle: (state) => state.modal.title
  }
}

export default overlaysStore
