const entity = {
  name: {
    singular: 'Banner',
    plural: 'Banners'
  },
  styles: {
    gridTemplateColumns: '20% 1fr'
  },
  collection: 'banners',
  tableHeaders: [
    { title: 'Nome', value: 'title' },
    { title: 'Código', value: 'slug' }
  ],
  model: {
    title: '',
    items: [],
    slug: '',
    published: false,
    date_in: null,
    date_out: null
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'text-field',
        label: 'Url amigável',
        model: 'slug',
        required: true
      },
      {
        type: 'date',
        label: 'Data de Entrada',
        model: 'date_in',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Data de Saída',
        model: 'date_out',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    fields: [
      {
        type: 'text-field',
        label: 'Nome',
        model: 'title',
        required: true
      },
      {
        type: 'gallery-cards',
        label: 'Imagens',
        model: 'items',
        required: true
      }
    ]
  }
}

export default entity
