import entity from '../../../models/loads/loads'

export default [
  {
    path: '/loads',
    meta: {
      entity,
      requiresPermissionTo: 'loads',
      crud: 'list'
    },
    component: () =>
      import(/* webpackChunkName: 'loads' */ '@/views/dynamic/DynamicIndex')
  },
  {
    path: '/loads/new',
    meta: {
      entity,
      requiresPermissionTo: 'loads',
      crud: 'form'
    },
    component: () =>
      import(/* webpackChunkName: 'loads' */ '@/views/dynamic/DynamicIndex')
  },
  {
    path: '/loads/:id',
    meta: {
      entity,
      requiresPermissionTo: 'loads',
      crud: 'form'
    },
    component: () =>
      import(/* webpackChunkName: 'loads' */ '@/views/dynamic/DynamicIndex')
  }
]
