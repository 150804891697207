import { localPersistence } from '@/services/LocalPersistenceService'

const settingsStore = {
  namespaced: true,
  state: {
    pageSize: {
      name: 'PageSize',
      value: () => localPersistence.get('mstrSettingsPageSize') || 10
    },
    animations: {
      name: 'Animations',
      value: () => localPersistence.get('mstrSettingsAnimations') || true
    },
    boxed: {
      name: 'Boxed',
      value: () => localPersistence.get('mstrSettingsAnimations') || true
    },
    language: {
      name: 'Language',
      value: () => localPersistence.get('mstrSettingsAnimations') || 'en'
    },
    tableColumns: {
      name: 'TableColumns',
      value: {}
    }
  },
  mutations: {
    setTableColumns: (state, data) => {
      state.tableColumns[data.collection] = data.columns
    }
  },
  actions: {
    setTableColumns: ({ dispatch }, settingData) => {
      dispatch('setTableColumns', settingData)
    }
  },
  getters: {
    all: (state) => state,
    pageSize: (state) => state.pageSize.value(),
    animations: (state) => state.animations.value(),
    boxed: (state) => state.boxed.value(),
    language: (state) => state.language.value(),
    tableColumns: (state) => state.tableColumns
  }
}

export default settingsStore
