import entity from '../../../models/school/students'

export default [
  {
    path: '/students',
    meta: {
      entity,
      requiresPermissionTo: 'students',
      crud: 'list'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/students/new',
    meta: {
      entity,
      requiresPermissionTo: 'students',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/students/:id',
    meta: {
      entity,
      requiresPermissionTo: 'students',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
