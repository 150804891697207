import _ from 'lodash'
import crudService from '@/services/CrudService'

import {
  states,
  pickupDeliveryStatus,
  paymentStatusOptions,
  loadStatus,
  taskStatus,
  businessType,
  paymentTerms,
  paymentMethods
} from './_contants'

const entity = {
  name: {
    singular: 'Load',
    plural: 'Loads'
  },
  importable: true,
  collection: 'loads',
  listFields: {
    fields: [
      'broker',
      'brokerFee',
      'carrier',
      'delivery',
      'driver',
      'loadBoard',
      'loadBoardId',
      'loadBoardStatus',
      'paymentStatus',
      'pickup',
      'price',
      'status',
      'vehicles'
    ],
    populate: ['broker', 'driver', 'carrier', 'tasks']
  },
  tableSchema: [
    {
      title: 'Origin',
      class: 'list-pickup',
      columnLayout: `
        <b class="text-capitalize">[pickup.city]</b>/<b class="text-uppercase">[pickup.state]</b><br>
        [pickup.scheduledDate]<br>
        [pickup.status]
        `
    },
    {
      title: 'Destination',
      class: 'list-delivery',
      columnLayout: `
      <b class="text-capitalize">[delivery.city]</b>/<b class="text-uppercase">[delivery.state]</b><br>
      [delivery.scheduledDate]<br>
      [delivery.status]`
    },
    {
      title: 'Items',
      class: 'list-vehicles',
      dataType: 'list',
      field: 'vehicles',
      columnLayoutPrepend: `<ul>`,
      columnLayout: `<li>
        [year] <b class="text-capitalize">[make]</b> - <span class="text-capitalize">[model]</span>
      </li>`,
      columnLayoutAppend: `</ul>`
    },
    {
      title: 'Carrier',
      class: 'list-carrier',
      columnLayout: `
        [carrier.name]<br>
        <i class="fas fa-user" data-v-981dd23a=""></i> [driver.name]
      `
    },
    {
      title: 'Broker',
      class: 'list-broker',
      columnLayout: `
        [broker.name]<br>
        <b>Fee:</b> [brokerFee]
      `
    },
    {
      title: 'Price',
      class: 'list-price',
      columnLayout: `
        [price]<br>
        <span class="badge badge-[paymentStatus]">[paymentStatus]</span>
      `
    },
    {
      title: 'LoadBoard',
      class: 'list-status',
      columnLayout: `
        [loadBoard]<br>
        id: [loadBoardId]<br>
        [loadBoardStatus]
      `
    },
    {
      title: 'Status',
      class: 'list-status',
      columnLayout: `
      <span class="badge badge-[status]">[status]</span>
      `
    }
  ],
  model: {
    orderNumber: '',
    loadBoardId: '',
    loadBoard: '',
    loadBoardStatus: 'new',
    status: 'new',
    price: '',
    paymentStatus: 'pending',
    brokerFee: '',
    instructions: '',

    broker: '',
    driver: '',
    carrier: '',

    pickup: {
      scheduledDate: '',
      workingHours: '',
      status: 'pending',
      realizedDate: '',

      contactName: '',
      contactEmail: '',
      contactPhone: '',
      contactMobilePhone: '',

      address: '',
      city: '',
      state: '',
      zip: '',
      businessType: '',
      notes: ''
    },

    delivery: {
      scheduledDate: '',
      workingHours: '',
      status: 'pending',
      realizedDate: '',

      contactName: '',
      contactEmail: '',
      contactPhone: '',
      contactMobilePhone: '',

      address: '',
      city: '',
      state: '',
      zip: '',
      businessType: '',
      notes: ''
    },

    vehicles: [],
    payments: []
  },
  config: {
    name: 'Config',
    fields: [
      {
        label: 'Tasks',
        type: 'timeline',
        model: 'tasks',
        arrayOf: {
          fields: [
            {
              label: 'Assigned to',
              model: 'assignedTo',
              type: 'select',
              multiple: false,
              options: async () => await crudService('users').getOptions(),
              class: 'col-lg-8'
            },
            {
              label: 'Status',
              model: 'status',
              type: 'select',
              options: taskStatus,
              class: 'col-lg-4'
            },
            { label: 'Description', model: 'description', type: 'textarea', class: 'col-lg-12'},
            // { label: 'priority', model: 'priority', type: 'select', options: ['P1', 'P2', 'P3'] },
            { label: 'position', model: 'position', type: 'hidden' },
            { label: 'Deadline', model: 'endDate', type: 'date', class: 'col-lg-6' },
            { label: 'Notes', model: 'notes', type: 'textarea' }
          ]
        }
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Details',
        fields: [
          {
            label: 'Order Number',
            model: 'orderNumber',
            class: 'col-lg-6'
          },
          {
            label: 'Status',
            model: 'status',
            class: 'col-lg-6',
            type: 'select',
            sortBy: 'position',
            options: async () => {
              const requestParams = {
                id: '6327a996b39d9daa1157abc4',
                fields: ['stages'],
                ignoreState: true
              }

              const response = await crudService(
                'workflows',
                requestParams
              ).getItemById()

              let list

              if (response) {
                list = response.data.stages.map((item) => ({
                  value: item._id,
                  label: item.name,
                  position: item.position
                }))

                list = _.sortBy(list, 'position')
                console.log(list)
                return list
              }

              return loadStatus
            }
          },
          {
            label: 'Contractor',
            model: 'carrier',
            type: 'select',
            options: async () => await crudService('carriers').getOptions(),
            class: 'col-lg-12'
          },
          {
            label: 'Driver',
            model: 'driver',
            type: 'select',
            options: async () => await crudService('drivers').getOptions(),
            class: 'col-lg-12'
          },
          {
            label: 'Broker',
            model: 'broker',
            type: 'select',
            options: async () => await crudService('brokers').getOptions(),
            class: 'col-lg-8'
          },
          { label: 'Broker Fee', model: 'brokerFee', class: 'col-lg-4' },
          { label: 'Price', model: 'price', class: 'col-lg-6' },
          {
            label: 'Payment Status',
            model: 'paymentStatus',
            class: 'col-lg-6',
            type: 'select',
            options: paymentStatusOptions
          },
          {
            label: 'Instructions',
            model: 'instructions',
            class: 'col-lg-12',
            type: 'textarea',
            hint: 'Driver will see this info.'
          }
        ]
      },
      {
        name: 'PickUp',
        fields: [
          {
            label: 'Pick up date',
            model: 'pickup.scheduledDate',
            type: 'date',
            class: 'col-lg-3'
          },
          {
            label: 'Working Ours',
            model: 'pickup.workingHours',
            class: 'col-lg-3'
          },
          {
            label: 'Status',
            model: 'pickup.status',
            type: 'select',
            options: pickupDeliveryStatus,
            class: 'col-lg-3'
          },
          {
            label: 'Picked up at',
            model: 'pickup.realizedDate',
            type: 'date',
            class: 'col-lg-3'
          },

          {
            label: 'Contact Name',
            model: 'pickup.contactName',
            class: 'col-lg-9'
          },
          {
            label: 'Business Type',
            model: 'pickup.businessType',
            type: 'select',
            options: businessType,
            class: 'col-lg-3'
          },
          {
            label: 'Contact Email',
            model: 'pickup.contactEmail',
            class: 'col-lg-6'
          },
          {
            label: 'Contact Phone',
            model: 'pickup.contactPhone',
            inputMask: '(###) ###-####',
            outputMask: '##########',
            class: 'col-lg-3'
          },
          {
            label: 'Contact MobilePhone',
            model: 'pickup.contactMobilePhone',
            inputMask: '(###) ###-####',
            outputMask: '##########',
            class: 'col-lg-3'
          },

          { label: 'Address', model: 'pickup.address', class: 'col-lg-5' },
          { label: 'City', model: 'pickup.city', class: 'col-lg-3' },
          {
            label: 'State',
            model: 'pickup.state',
            class: 'col-lg-2',
            type: 'select',
            options: states
          },
          {
            label: 'Zip',
            model: 'pickup.zip',
            class: 'col-lg-2',
            inputMask: '#####',
            outputMask: '#####'
          },

          {
            label: 'Notes',
            model: 'pickup.notes',
            type: 'textarea',
            class: 'col-lg-12'
          }
        ]
      },
      {
        name: 'Delivery',
        fields: [
          {
            label: 'Delivery date',
            model: 'delivery.scheduledDate',
            type: 'date',
            class: 'col-lg-3'
          },
          {
            label: 'Working Ours',
            model: 'delivery.workingHours',
            class: 'col-lg-3'
          },
          {
            label: 'Status',
            model: 'delivery.status',
            type: 'select',
            options: pickupDeliveryStatus,
            class: 'col-lg-3'
          },
          {
            label: 'Delivered at',
            model: 'delivery.realizedDate',
            type: 'date',
            class: 'col-lg-3'
          },

          {
            label: 'Contact Name',
            model: 'delivery.contactName',
            class: 'col-lg-9'
          },
          {
            label: 'Business Type',
            model: 'delivery.businessType',
            type: 'select',
            options: businessType,
            class: 'col-lg-3'
          },
          {
            label: 'Contact Email',
            model: 'delivery.contactEmail',
            class: 'col-lg-6'
          },
          {
            label: 'Contact Phone',
            model: 'delivery.contactPhone',
            inputMask: '(###) ###-####',
            outputMask: '##########',
            class: 'col-lg-3'
          },
          {
            label: 'Contact MobilePhone',
            model: 'delivery.contactMobilePhone',
            inputMask: '(###) ###-####',
            outputMask: '##########',
            class: 'col-lg-3'
          },

          { label: 'Address', model: 'delivery.address', class: 'col-lg-5' },
          { label: 'City', model: 'delivery.city', class: 'col-lg-3' },
          {
            label: 'State',
            model: 'delivery.state',
            class: 'col-lg-2',
            type: 'select',
            options: states
          },
          {
            label: 'Zip',
            model: 'delivery.zip',
            class: 'col-lg-2',
            inputMask: '#####',
            outputMask: '#####'
          },

          {
            label: 'Notes',
            model: 'delivery.notes',
            type: 'textarea',
            class: 'col-lg-12'
          }
        ]
      },
      {
        name: 'Vehicles',
        fields: [
          {
            label: 'Vehicles',
            type: 'array',
            model: 'vehicles',
            arrayOf: {
              fields: [
                { label: 'Make', model: 'make', class: 'col-lg-2' },
                { label: 'Model', model: 'model', class: 'col-lg-2' },
                { label: 'Year', model: 'year', class: 'col-lg-1' },
                { label: 'Color', model: 'color', class: 'col-lg-2' },
                { label: 'Vin', model: 'vin', class: 'col-lg-3' },
                {
                  label: 'Is Inoperable',
                  model: 'is_inoperable',
                  type: 'switch',
                  class: 'col-lg-2'
                },
                { label: 'Info', model: 'info', class: 'col-lg-12' },
                {
                  label: 'Posição',
                  model: 'position',
                  required: true,
                  class: 'd-none'
                }
              ]
            }
          }
        ]
      },
      {
        name: 'LoadBoard Info',
        fields: [
          {
            label: 'LoadBoard',
            model: 'loadBoard',
            class: 'col-lg-4',
            readonly: true
          },
          {
            label: 'LoadBoardId',
            model: 'loadBoardId',
            class: 'col-lg-4',
            readonly: true
          },
          {
            label: 'LoadBoardStatus',
            model: 'loadBoardStatus',
            class: 'col-lg-4'
          },
          {
            label: 'Carrier',
            model: 'carrier',
            type: 'select',
            options: async () => await crudService('carriers').getOptions(),
            class: 'col-lg-6'
          }
        ]
      },
      {
        name: 'Payments',
        fields: [
          {
            type: 'array',
            label: 'Payments',
            model: 'payments',
            arrayOf: {
              fields: [
                {
                  label: 'Terms',
                  model: 'terms',
                  type: 'select',
                  options: paymentTerms,
                  class: 'col-lg-2'
                },
                {
                  label: 'Method',
                  model: 'method',
                  type: 'select',
                  options: paymentMethods,
                  class: 'col-lg-2'
                },
                { label: 'Amount', model: 'amount', class: 'col-lg-2' },
                {
                  label: 'Ref. Number',
                  model: 'reference_number',
                  class: 'col-lg-2'
                },
                {
                  label: 'Sent',
                  type: 'date',
                  model: 'sent_date',
                  class: 'col-lg-2'
                },
                { label: 'Notes', model: 'notes', class: 'col-lg-12' },
                { label: 'Posição', model: 'position', required: true }
              ]
            }
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
