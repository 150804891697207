import crudService from '@/services/CrudService'

const entity = {
  name: {
    singular: 'Driver',
    plural: 'Drivers'
  },
  collection: 'drivers',
  tableHeaders: [
    { title: 'Name', value: 'name' },
    { title: 'MobilePhone', value: 'mobilePhone' },
    { title: 'Phone', value: 'landline' },
    { title: 'Email', value: 'email' }
  ],
  model: {
    name: '',
    email: '',
    phone: '',
    mobilePhone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    notes: '',
    loads: [],
    carrier: ''
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        label: 'Carrier',
        model: 'carrier',
        type: 'select',
        options: async () => await crudService('carriers').getOptions(),
        class: 'col-lg-12'
      },
      { label: 'Notes', model: 'notes', class: 'col-lg-12', type: 'textarea' }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Details',
        fields: [
          { label: 'Name', model: 'name', class: 'col-lg-12' },
          { label: 'Email', model: 'email', class: 'col-lg-6' },
          { label: 'Phone', model: 'phone', class: 'col-lg-3' },
          { label: 'Mobile Phone', model: 'mobilePhone', class: 'col-lg-3' },
          { label: 'Address', model: 'address', class: 'col-lg-12' },
          { label: 'City', model: 'city', class: 'col-lg-6' },
          { label: 'State', model: 'state', class: 'col-lg-3' },
          { label: 'Zip', model: 'zip', class: 'col-lg-3' }
        ]
      },
      {
        name: 'Loads',
        groupType: 'list',
        entity: 'loads',
        itemsPerPage: 50,
        fields: ['orderNumber', 'status'],
        tableHeaders: [
          { title: 'orderNumber', value: 'orderNumber' },
          { title: 'status', value: 'status' },
          { title: 'Actions', value: 'action', sortable: false }
        ],
        relatedCollection: 'driver',
        relatedCollectionIdField: 'driver',
        editOnDrawer: true
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
