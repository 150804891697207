import crudService from '@/services/CrudService'
import sectionsEntity from './sections'
import contentsEntity from './contents'

const entity = {
  name: {
    singular: 'Seção',
    plural: 'Seções'
  },
  collection: 'sections',
  tableHeaders: [
    { title: 'Seção', value: 'title' },
    { title: 'Tipo', value: 'sectionType' },
    { title: 'Url Amigável', value: 'slug' }
  ],
  model: {
    parent: null,
    slug: null,
    title: null,
    sectionType: 'single',
    summary: null,
    position: '',
    sidebar: false,
    fileMainImage: [],
    fileSecondaryImage: [],
    date_in: null,
    date_out: null,
    pudlished: true,
    clickableContent: true,
    searchableContent: true
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        type: 'select',
        label: 'Subseção de',
        model: 'parent',
        multiple: true,
        options: async () => await crudService('modules').getOptions(),
        class: 'xs12 sm12 md12 col-lg-12'
      },
      {
        type: 'date',
        label: 'Data de Entrada',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateIn',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Data de Saída',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateOut',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'radio',
        model: 'sectionType',
        label: 'Tipo de seção',
        options: [
          { label: 'Único', value: 'single' },
          { label: 'Lista simples', value: 'list' },
          { label: 'Grid Imagens', value: 'imageList' },
          { label: 'Cards', value: 'cardList' }
        ],
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'radio',
        model: 'sidebar',
        label: 'Sidebar',
        options: [
          { label: 'Nenhuma', value: 'none' },
          { label: 'Esquerda', value: 'left' },
          { label: 'Direita', value: 'right' }
        ],
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'switch',
        model: 'clickableContent',
        label: 'Conteúdo Clicável'
      },
      {
        type: 'switch',
        model: 'searchableContent',
        label: 'Exibir o conteúdo na busca'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Detalhes',
        fields: [
          {
            type: 'text-field',
            label: 'Título',
            model: 'title',
            required: true,
            class: 'xs12 sm12 md6 col-lg-6'
          },
          {
            type: 'text-field',
            label: 'Url amigável',
            model: 'slug',
            required: true,
            class: 'xs12 sm12 md6 col-lg-6'
          },
          {
            type: 'text-field',
            label: 'Resumo',
            model: 'summary',
            required: false,
            class: 'xs12 sm12 md12 col-lg-12'
          },
          {
            type: 'single-file',
            label: 'Imagem grande',
            model: 'fileMainImage',
            multiple: false,
            required: false,
            hideInput: true,
            class: 'xs12 sm12 md6 col-lg-6'
          },
          {
            type: 'single-file',
            label: 'Imagem pequena',
            model: 'fileSecondaryImage',
            multiple: false,
            required: false,
            hideInput: true,
            class: 'xs12 sm12 md6 col-lg-6'
          }
        ]
      },
      {
        name: 'Sub-seções',
        groupType: 'list',
        entity: sectionsEntity,
        relatedCollection: 'sections',
        relatedCollectionIdField: 'parent',
        editOnDrawer: false
      },
      {
        name: 'Conteúdo',
        groupType: 'list',
        entity: contentsEntity,
        relatedCollection: 'sections',
        relatedCollectionIdField: 'sections',
        editOnDrawer: false
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
