const entity = {
  name: {
    singular: 'Página',
    plural: 'Páginas'
  },
  styles: {
    gridTemplateColumns: '30% 1fr',
    gridTemplateAreas: '"main secondary"'
  },
  collection: 'pages',
  tableHeaders: [
    { title: 'Título', value: 'title', type: 'string' },
    { title: 'Url Amigável', value: 'url', type: 'string' },
    { title: 'Criado em ', value: 'createdAt', type: 'date' },
    { title: 'Atualizado em', value: 'updatedAt', type: 'date' },
    { title: 'Entrada ', value: 'dateIn', type: 'date' },
    { title: 'Saída', value: 'dateOut', type: 'date' },
    { title: 'Publicado', value: 'published', type: 'boolean' }
  ],
  model: {
    featuredImage: {
      path: '',
      size: 0,
      uploaded: false,
      url: ''
    },
    featuredImageMobile: {
      path: '',
      size: 0,
      uploaded: false,
      url: ''
    },
    title: '',
    body: '',
    summary: '',
    url: '',
    published: false,
    dateIn: '',
    dateOut: ''
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        type: 'text-field',
        label: 'Título',
        model: 'title',
        required: true
      },
      {
        type: 'text-field',
        label: 'Resumo',
        model: 'summary',
        required: false
      },
      {
        type: 'url',
        label: 'Url amigável',
        model: 'slug',
        required: true
      },
      {
        type: 'date',
        label: 'Data de Entrada',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateIn',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Data de Saída',
        hint: 'Deixe em branco caso não tenha data limite.',
        model: 'dateOut',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Conteúdo',
        fields: [
          {
            type: 'code-editor',
            label: 'Conteúdo',
            model: 'body',
            required: false,
            language: 'xml'
          }
        ]
      },
      {
        name: 'Imagens',
        fields: [
          {
            type: 'single-file',
            label: 'Imagem principal - Desktop',
            model: 'featuredImage',
            hideInput: true,
            class: 'xs12 sm12 md6 col-lg-6'
          },
          {
            type: 'single-file',
            label: 'Mobile',
            model: 'featuredImageMobile',
            hideInput: true,
            class: 'xs12 sm12 md6 col-lg-6'
          }
        ]
      },
      {
        name: 'Estilos',
        fields: [
          {
            type: 'code-editor',
            language: 'css',
            label: 'Estilos',
            model: 'styles',
            required: false
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }
}

export default entity
