import 'bootstrap'

import { createApp } from 'vue'

import router from './router'
import store from './store/store'

import App from './App.vue'

import './registerServiceWorker'

import './assets/scss/main.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue-json-pretty/lib/styles.css'

const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
