import crudService from '@/services/CrudService'

const entity = {
  name: {
    singular: 'Dashboard',
    plural: 'Dashboards'
  },
  collection: 'dashboards',
  tableHeaders: [
    { title: 'Title', value: 'title' },
    { title: 'Key', value: 'key' }
  ],
  model: {
    title: '',
    key: ''
  },
  config: {
    name: 'Title',
    fields: [
      {
        label: 'Title',
        model: 'title',
        required: true,
        class: 'col-md-12'
      },
      {
        label: 'Key',
        model: 'key',
        required: true,
        class: 'col-md-12'
      }
    ]
  },
  schema: {},
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
