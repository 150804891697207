<template>
  <div class="secondary-sidebar">
    <div class="secondary-sidebar-bar">
      <a id="logoMaestro" class="logo-box">
        <img src="../../assets/logo-maestro.png" alt="Maestro" />
      </a>
    </div>
    <div class="secondary-sidebar-menu">
      <MainMenu />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import MainMenu from './MainMenu'

const store = useStore()
const currentUser = computed(() => store.getters['authentication/currentUser'])
</script>

<style lang="scss" scoped>
#logoMaestro.logo-box {
  text-align: center;

  .collapsed-sidebar & {
    visibility: visible;
  }

  img {
    width: 30px;
  }
}
</style>
