import entity from '../../../models/marketing/mailings'

export default [
  {
    path: '/mailings',
    meta: {
      entity,
      requiresPermissionTo: 'mailings',
      crud: 'list'
    },
    component: () =>
      import(
        /* webpackChunkName: "mailings" */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/mailings/new',
    meta: {
      entity,
      requiresPermissionTo: 'mailings',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: "mailings" */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/mailings/:id',
    meta: {
      entity,
      requiresPermissionTo: 'mailings',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: "mailings" */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
