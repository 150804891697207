import entity from '../../../models/cms/contents'

export default [
  {
    path: '/contents',
    meta: {
      entity,
      requiresPermissionTo: 'contents',
      crud: 'list'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/contents/new',
    meta: {
      entity,
      requiresPermissionTo: 'contents',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/contents/:id',
    meta: {
      entity,
      requiresPermissionTo: 'contents',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
