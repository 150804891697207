import cookie from 'vue-cookies'

const localPersistence = {
  remove: (key) => {
    console.log(cookie.get(key))
    cookie.remove(key)
    console.log(cookie.get(key))
  },
  set: (key, obj) => cookie.set(key, obj),
  get: (key) => cookie.get(key),
  has: (key) => !!cookie.get(key),
  toJSON: () => JSON.stringify(cookie)
}

const getUserFromLocalStorage = function () {
  const user = localPersistence.get('mstrAuthUsr')

  if (user) {
    user.token = localPersistence.get('mstrToken')
    return user
  }

  return null
}

export { localPersistence, getUserFromLocalStorage }
