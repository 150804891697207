import bannersRoutes from './banners'
import contentsRoutes from './contents'
import coursesRoutes from './courses'
import galleriesRoutes from './galleries'
import menusRoutes from './menus'
import pagesRoutes from './pages'
import sectionsRoutes from './sections'

export default [
  ...bannersRoutes,
  ...contentsRoutes,
  ...coursesRoutes,
  ...galleriesRoutes,
  ...menusRoutes,
  ...pagesRoutes,
  ...sectionsRoutes
]
