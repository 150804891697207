import entity from '../../../models/events/events'

export default [
  {
    path: '/events',
    meta: {
      entity,
      requiresPermissionTo: 'events'
    },
    component: () =>
      import(
        /* webpackChunkName: 'events' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/events/new',
    meta: {
      entity,
      requiresPermissionTo: 'events'
    },
    component: () =>
      import(
        /* webpackChunkName: 'events' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/events/:id',
    meta: {
      entity,
      requiresPermissionTo: 'events'
    },
    component: () =>
      import(
        /* webpackChunkName: 'events' */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
