import crudService from '@/services/CrudService'

const entity = {
  name: {
    singular: 'Ordem de Serviço',
    plural: 'Ordens de Serviço'
  },
  collection: 'service-orders',
  tableHeaders: [
    { title: 'Número', value: 'number' },
    { title: 'Cliente', value: 'client.name' },
    { title: 'Início', value: 'startDate', type: 'date' },
    { title: 'Entrega', value: 'endDate', type: 'date' },
    { title: 'Status', value: 'status' }
  ],
  model: {
    client: '',
    number: '',
    notes: '',
    startDate: '',
    endDate: '',
    status: ''
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        type: 'select',
        label: 'Cliente',
        model: 'client',
        multiple: false,
        options: async () => await crudService('clients').getOptions(),
        class: 'xs12 sm12 md12 col-lg-12'
      },
      {
        type: 'text-field',
        label: 'Número da Ordem de Serviço',
        model: 'number',
        class: 'xs12 sm12 md12 col-lg-12'
      },
      {
        type: 'date',
        label: 'Início',
        model: 'startDate',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Término',
        hint: 'Para OSs em aberto, insira a estimativa.',
        model: 'endDate',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'radio',
        label: 'Status',
        model: 'status',
        options: [
          { label: 'Em análise', value: 'investigating' },
          { label: 'Aguardando aprovação', value: 'waiting_approval' },
          { label: 'Em execução', value: 'in_progress' },
          { label: 'Concluído', value: 'finished' }
        ],
        class: 'xs12 sm12 md6 col-lg-6'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Detalhes da OS',
        fields: [
          {
            type: 'editor',
            label: 'Notas',
            model: 'notes',
            required: false
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
