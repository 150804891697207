import entity from '../../../models/loads/brokers'

export default [
  {
    path: '/brokers',
    meta: {
      entity,
      requiresPermissionTo: 'brokers',
      crud: 'list'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/brokers/new',
    meta: {
      entity,
      requiresPermissionTo: 'brokers',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/brokers/:id',
    meta: {
      entity,
      requiresPermissionTo: 'brokers',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
