import entity from '../../../models/marketing/campaigns'

export default [
  {
    path: '/campaigns',
    meta: {
      entity,
      requiresPermissionTo: 'campaigns',
      crud: 'list'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/campaigns/new',
    meta: {
      entity,
      requiresPermissionTo: 'campaigns',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/campaigns/:id',
    meta: {
      entity,
      requiresPermissionTo: 'campaigns',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
