const messagesStore = {
  namespaced: true,
  state: {
    messages: []
  },
  mutations: {
    addMessage: (state, message) => {
      state.messages.push(message)
    },
    removeMessage: (state, id) => {
      state.messages = Array.from(
        state.messages.filter((message) => message.id !== id)
      )
    }
  },
  actions: {
    addMessage: ({ commit }, message) => {
      message.id = Date.now()
      commit('addMessage', message)

      setTimeout(function () {
        commit('removeMessage', message.id)
      }, 5000)
    },

    removeMessage: ({ commit, state }, id) => {
      commit('removeMessage', id)
    }
  },
  getters: {
    messages: (state) => state.messages
  }
}

export default messagesStore
