const entity = {
  name: {
    singular: 'Galeria',
    plural: 'Galerias'
  },
  collection: 'galleries',
  tableHeaders: [
    { title: 'Título', value: 'title' },
    { title: 'Chave', value: 'key' }
  ],
  model: {
    title: null,
    key: '',
    items: [],
    published: false,
    dateIn: null,
    dateOut: null
  },
  config: {
    name: 'Config',
    fields: [
      {
        type: 'date',
        label: 'Data de Entrada',
        model: 'dateIn',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Data de Saída',
        model: 'dateOut',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Galeria',
        fields: [
          {
            type: 'text-field',
            label: 'Título',
            model: 'title',
            required: true
          },
          {
            type: 'text-field',
            label: 'Chave',
            model: 'key',
            required: true
          },
          {
            type: 'multiple-files',
            label: 'Imagens',
            model: 'items'
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
