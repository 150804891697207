import brokersRoutes from './brokers'
import carriersRoutes from './carriers'
import driversRoutes from './drivers'
import loadsRoutes from './loads'
import paymentsRoutes from './payments'

export default [
  ...brokersRoutes,
  ...carriersRoutes,
  ...driversRoutes,
  ...loadsRoutes,
  ...paymentsRoutes
]
