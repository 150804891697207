import { createRouter, createWebHistory } from 'vue-router'

import baseRoutes from './routes/base/index'
import cmsRoutes from './routes/cms/index'
import eventsRoutes from './routes/events/index'
import formsRoutes from './routes/forms/index'
import loadsRoutes from './routes/loads/index'
import marketingRoutes from './routes/marketing/index'
import mlmRoutes from './routes/mlm/index'
import schoolRoutes from './routes/school/index'
import servicesRoutes from './routes/services/index'

import store from '../store/store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...baseRoutes,
    ...cmsRoutes,
    ...eventsRoutes,
    ...formsRoutes,
    ...loadsRoutes,
    ...marketingRoutes,
    ...mlmRoutes,
    ...schoolRoutes,
    ...servicesRoutes,
    {
      path: '/',
      name: 'dashboard',
      component: () => import('../views/MainDashboard.vue')
    },
    {
      path: '/icons',
      name: 'icons',
      component: () => import('../views/TheIcons.vue')
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = !to.matched.some(({ meta }) => {
    return meta.public
  })

  console.log(`Going to ${to.name}. Does it requires auth?', ${requiresAuth}.`)

  if (requiresAuth) {
    let user = store?.getters['authentication/currentUser']
    console.log('Is user available on store?', user)

    if (!user) {
      await store.dispatch('authentication/getFromLocalPersistence', user)
      if (store.getters['authentication/isAuthenticated']) return next()

      return next({ name: 'login' })
    }

    return next()
  }

  return next()
})

export default router
