export default [
  {
    path: '/register',
    name: 'register',
    meta: { public: true },
    component: () =>
      import(/* webpackChunkName: "register" */ '@/views/auth/AuthRegister')
  },
  {
    path: '/login',
    name: 'login',
    meta: { public: true },
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/auth/AuthLogin')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () =>
      import(/* webpackChunkName: "logout" */ '@/views/auth/AuthLogout')
  },
  {
    path: '/password-reset/:token',
    name: 'password-reset',
    meta: { public: true },
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ '@/views/auth/AuthPasswordReset'
      )
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: { public: true },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '@/views/auth/AuthForgotPassword'
      )
  },
  {
    path: '/a/:code',
    name: 'account-set',
    meta: { public: true },
    component: () =>
      import(/* webpackChunkName: "account-set" */ '@/views/auth/AuthAccountSet')
  }
]
