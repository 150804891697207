const entity = {
  name: {
    singular: 'Workflow',
    plural: 'Workflows'
  },
  collection: 'workflows',
  tableHeaders: [
    { title: 'name', value: 'name' },
    { title: 'status', value: 'status' },
    { title: 'visible', value: 'visible' }
  ],
  model: {
    status: 'active',
    name: '',
    stages: [],
    visible: true
  },
  config: {
    name: 'Config',
    fields: [
      {
        label: 'Name',
        model: 'name',
        required: true,
        class: 'col-lg-12'
      },
      {
        type: 'switch',
        model: 'visible',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Stages',
        fields: [
          {
            label: 'Stages',
            model: 'stages',
            type: 'array',
            arrayOf: {
              fields: [
                {
                  label: 'Name',
                  model: 'name'
                },
                {
                  label: 'Tasks',
                  model: 'tasks',
                  type: 'array',
                  arrayOf: {
                    fields: [
                      {
                        type: 'textarea',
                        label: 'Description',
                        model: 'description',
                        class: 'col-lg-12',
                        showClass: 'col-lg-3'
                      },
                      {
                        label: 'Days from d0',
                        model: 'daysAfter',
                        class: 'col-lg-3',
                        showClass: 'col-lg-3',
                        hint: 'Number of days this task starts after D-0 of the project.'
                      },
                      {
                        label: 'Estimate',
                        model: 'estimate',
                        class: 'col-lg-3',
                        showClass: 'col-lg-3'
                      },
                      {
                        type: 'text-field',
                        inputType: 'hidden',
                        label: 'Posição',
                        model: 'position'
                      }
                    ]
                  },
                  populate: ['assignedTo']
                }
              ]
            }
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
