import { getUserFromLocalStorage } from '@/services/LocalPersistenceService'
import { authService } from '@/services/AuthService'
import { localPersistence } from '@/services/LocalPersistenceService'

const authenticationStore = {
  namespaced: true,
  state: {
    authenticatedUser: null
  },
  mutations: {
    setAuthenticatedUser: (state, user) => {
      delete user.password
      state.authenticatedUser = user
    },
    setAccount: (state, account) => {
      state.account = account
    },
    logoutUser: (state) => {
      state.authenticatedUser = null
    }
  },
  actions: {
    authenticate: async ({ commit }, credentials) => {
      try {
        const response = await authService.authenticate(credentials)

        if (response.status === 'error') return response

        let user = response

        user.id = user.userId

        commit('setAuthenticatedUser', { ...user })

        const { modules, token } = user

        delete user.modules
        delete user.token

        console.log('user returned', JSON.stringify(user))

        localPersistence
          .set('mstrToken', token, '1d')
          .set('mstrModules', JSON.stringify(modules), '1d')
          .set('mstrAcId', user.accountId, '30d')
          .set('mstrAuthUsr', JSON.stringify(user), '1d')

        console.log('user on localStorage', localPersistence.get('mstrAuthUsr'))

        return user
      } catch (error) {
        throw new Error(error)
      }
    },

    register: async ({ dispatch }, credentials) => {
      let user = await authService.register(credentials)
      if (user.accountId && user._id) dispatch('authenticate', credentials)
    },

    forgotPassword: async (credentials) => {
      let response = await authService.forgotPassword(credentials)
      return response
    },

    validateResetPasswordToken: async (token) => {
      let response = await authService.validateResetPasswordToken(token)
      return response
    },

    confirmAccount: async (token) => {
      let response = await authService.confirmAccount(token)
      return response
    },

    getAccount: async ({ commit }, code) => {
      let response = await authService.getAccount(code)
      console.log(response.data.accountId)
      localPersistence.set('mstrAcId', response.data.accountId, '30d')
      commit('setAccount', response)
      return response
    },

    passwordReset: async (credentials) => {
      let response = await authService.passwordReset(credentials)
      return response
    },

    getFromLocalPersistence: async ({ commit, dispatch }) => {
      const user = await getUserFromLocalStorage()
      console.log('userFromLocalStorage', user)

      if (user) {
        const refreshedUser = await dispatch('refreshUser', user)

        commit('setAuthenticatedUser', refreshedUser)
        return user
      }

      dispatch('logout')
      return null
    },

    refreshUser: async ({}, user) => {
      let response = await authService.refresh({ token: user.token })
      return response
    },

    logout: async ({ commit }) => {
      console.log('LOGOUT!!!')
      localPersistence.remove('mstrAuthUsr')
      localPersistence.remove('mstrToken')
      localPersistence.remove('mstrModules')
      commit('logoutUser')
    }
  },

  getters: {
    isAuthenticated: (state) => !!state.authenticatedUser,
    currentUser: (state) => state.authenticatedUser
  }
}

export default authenticationStore
