import classesRoutes from './classes'
import gradesRoutes from './grades'
import parentsRoutes from './parents'
import studentsRoutes from './students'
import teachersRoutes from './teachers'

export default [
  ...classesRoutes,
  ...gradesRoutes,
  ...parentsRoutes,
  ...studentsRoutes,
  ...teachersRoutes
]
