<template>
  <div
    class="general-settings__container"
    :class="{ '--show': state.isActive }"
  >
    <div class="settings-menu-btn">
      <button class="settings-menu-link" @click="state.isActive = true">
        <i class="fas fa-wrench"></i>
      </button>
    </div>
    <div class="settings-sidebar">
      <div class="settings-sidebar-content">
        <div class="settings-sidebar-header">
          <span>Settings</span>
          <button class="settings-menu-close" @click="state.isActive = false">
            <i class="icon-close"></i>
          </button>
        </div>
        <div class="right-sidebar-settings">
          <span class="settings-title">General Settings</span>
          <ul class="sidebar-setting-list list-unstyled">
            <li v-for="(setting, i) in settings" :key="`stng${i}`">
              <span class="settings-option">{{ setting.name }}</span>
              <input
                type="checkbox"
                class="js-switch"
                :checked="!!setting.value"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="settings-overlay" @click="state.isActive = false"></div>
  </div>
</template>
<script setup>
import { computed, reactive } from '@vue/runtime-core'
import { useStore } from 'vuex'

const store = useStore()
const settings = computed(() => store.getters['settings/all'])

const state = reactive({
  isActive: false
})
</script>
<style lang="scss">
/* Settings */
.general-settings__container {
  .settings-menu-btn {
    height: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(255,255,255,.1);
    padding: 15px 0;

    button {
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      text-align: center;
      widtH: 100%;
      display: block;
      padding: 5px 10px;
      transition: all .2s;
      background: transparent;
      border: none;
    }
  }

  .settings-sidebar {
    z-index: 1021;
    position: fixed;
    height: 100%;
    width: 261px;
    background: #fff;
    border-right: 1px solid #E5E9EC;
    transform: translateX(-100%);
    transition: all .2s;
    top: 0;
  }

  .settings-overlay {
    z-index: 1020;
    background: rgba(0,0,0,.3);
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0px;
    transition: opacity .5s;
  }

  &.--show .settings-overlay {
    opacity: 1;
    right: 0;
    width: 100%;
  }

  &.--show .settings-sidebar {
    transform: translateY(0);
  }

  .settings-sidebar-header {
    height: 61px;
    border-bottom: 1px solid #E5E9EC;
  }

  .settings-menu-close {
    height: 60px;
    width: 60px;
    line-height: 60px;
    display: block;
    float: right;
    text-align: center;
    font-size: 20px;
    background: transparent;
    border: none;
  }

  .settings-sidebar-header span {
    font-size: 18px;
    font-weight: bold;
    line-height: 60px;
    padding-left: 21px;
    color: #333;
  }
}
</style>
