<template>
  <ul id="mainMenu" class="accordion-menu">
    <li :class="{ 'active-page open': state.activeMenu === 'dashboard' }">
      <RouterLink to="/">
        <i class="menu-icon icon-home4"></i>
        <span>Dashboard</span>
      </RouterLink>
    </li>
    <li
      v-for="item in state.menuItems"
      :key="item._id"
      :class="{ 'active-page open': item._id === state.activeMenu }"
    >
      <RouterLink
        @click="activateMenu(item)"
        :to="item.route.startsWith('/') ? item.route : '/' + item.route"
        replace
        exact
      >
        <i class="menu-icon" :class="item.icon"></i>
        <span>{{ item.name }}</span>
        <i
          v-if="isArrayNotEmpty(item.children)"
          class="accordion-icon fas fa-angle-left"
        ></i>
      </RouterLink>

      <ul class="sub-menu">
        <li v-for="child in item.children" :key="child._id">
          <RouterLink :to="child.route">
            {{ child.name }}
          </RouterLink>
        </li>
      </ul>
    </li>
    <li class="menu-divider"></li>
  </ul>
</template>
<script setup>
import { useStore } from 'vuex'
import { onMounted, reactive, computed } from 'vue'

const isArrayNotEmpty = (val) => Array.isArray(val) && !!val.length

const activateMenu = (item) =>
  (state.activeMenu = state.activeMenu === item._id ? 'dashboard' : item._id)

const store = useStore()

const currentUser = computed(() => store.getters['authentication/currentUser'])

const state = reactive({
  loaded: false,
  activeMenu: 'dashboard',
  menuOrModules: null,
  menuItems: null,
  menu: null,
  modules: null
})

onMounted(() => {
  const user = currentUser.value
  state.menu = user.menu
  state.modules = user.modules
  state.menuItems =
    Array.isArray(state.menu) && state.menu.length > 0
      ? state.menu[0].items
      : state.modules
  state.menuOrModules = 'modules'
})

const openMenu = (id) => {
  state.activeMenu = state.activeMenu === id ? null : id
}
</script>
<style lang="scss">
#mainMenu {
  .menu-icon {
    width: 20px;
  }

  .sub-menu {
    display: none;

    li a {
      opacity: 1;
    }
  }

  .open,
  .active-page {
    .sub-menu {
      display: block;
    }
  }

  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
}
</style>
