export const entity = {
  name: {
    singular: 'Curso',
    plural: 'Cursos'
  },
  collection: 'courses',
  tableHeaders: [
    { title: 'Título', value: 'title' },
    { title: 'URL Amigável', value: 'slug' },
    { title: 'Criado em ', value: 'createdAt', type: 'date' },
    { title: 'Atualizado em', value: 'updatedAt', type: 'date' }
  ],
  config: {
    name: 'Config',
    fields: [
      {
        type: 'text-field',
        label: 'Url amigável',
        model: 'slug',
        required: true
      },
      {
        type: 'date',
        label: 'Data de Entrada',
        model: 'date_in',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Data de Saída',
        model: 'date_out',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      },
      {
        type: 'switch',
        model: 'hideSidebar',
        label: 'Ocultar sidebar toda'
      }
    ]
  },
  model: {
    title: '',
    fileTeaser: {},
    fileImageHome: {},
    body: '',
    advantages: [],
    iconPanelTitle: 'Vantagens',
    generalInformations: [],
    summary: '',
    price: '',
    discount: '',
    additionalPages: [],
    imageGallery: [],
    hideSidebar: false,
    hidePrice: false,
    hideAdditionalPages: false,
    hideCallToActionButton: false,
    callToActionButtonText: '',
    callToActionButtonLink: '',
    callToActionButtonTargetBlank: false,

    slug: '',
    published: false,
    date_in: null,
    date_out: null
  },
  schema: {
    groups: [
      {
        name: 'Informações',
        fields: [
          {
            type: 'text-field',
            label: 'Nome',
            model: 'title',
            class: 'xs12 sm12 md6 col-lg-6'
          },
          {
            type: 'text-field',
            label: 'Mensalidade',
            model: 'price',
            class: 'xs12 sm12 md3 col-lg-3'
          },
          {
            type: 'text-field',
            label: 'Desconto',
            model: 'discount',
            class: 'xs12 sm12 md3 col-lg-3'
          },
          {
            type: 'single-file',
            label: 'Imagem do Topo',
            model: 'fileTeaser',
            required: false,
            hideInput: true,
            class: 'xs12 sm12 md6 col-lg-6'
          },
          {
            type: 'single-file',
            label: 'Imagem para cursos na home',
            model: 'fileImageHome',
            required: false,
            hideInput: true,
            class: 'xs12 sm12 md6 col-lg-6'
          }
        ]
      },
      {
        name: 'Texto principal',
        fields: [
          {
            type: 'editor',
            label: 'Texto principal',
            model: 'body'
          }
        ]
      },
      {
        name: 'Informações Gerais',
        fields: [
          {
            type: 'array',
            arrayOf: {
              fields: [
                {
                  type: 'text-field',
                  inputType: 'text',
                  label: 'Título',
                  model: 'title',
                  required: true
                },
                {
                  type: 'editor',
                  label: 'Conteúdo',
                  model: 'description',
                  required: true
                },
                {
                  type: 'text-field',
                  inputType: 'text',
                  label: 'Posição',
                  model: 'position',
                  required: true
                }
              ]
            },
            label: 'Informações gerais',
            model: 'generalInformations'
          }
        ]
      },
      {
        name: 'Outras informações',
        fields: [
          {
            type: 'array',
            arrayOf: {
              fields: [
                {
                  type: 'text-field',
                  inputType: 'text',
                  label: 'Título',
                  model: 'title',
                  required: true
                },
                {
                  type: 'text-field',
                  inputType: 'text',
                  label: 'URL',
                  model: 'description',
                  required: true
                },
                {
                  type: 'switch',
                  model: 'target',
                  label: 'Nova janela'
                },
                {
                  type: 'text-field',
                  inputType: 'text',
                  label: 'Posição',
                  model: 'position',
                  required: true
                }
              ]
            },
            label: 'Páginas adicionais',
            model: 'additionalPages',
            translations: {
              title: 'Título',
              description: 'URL',
              position: 'Posição'
            }
          }
        ]
      },
      {
        name: 'Painel',
        fields: [
          {
            type: 'text-field',
            label: 'Titulo',
            model: 'iconPanelTitle'
          },
          {
            type: 'gallery-cards',
            label: 'Items',
            model: 'advantages'
          }
        ]
      },
      {
        name: 'Galeria',
        fields: [
          {
            type: 'gallery-cards',
            label: 'Galeria de Imagens',
            model: 'gallery'
          }
        ]
      },
      {
        name: 'Barra lateral',
        fields: [
          {
            type: 'editor',
            label: 'Conteúdo',
            model: 'sidebarContent'
          },
          {
            type: 'switch',
            model: 'hidePrice',
            label: 'Ocultar mensalidade'
          },
          {
            type: 'switch',
            model: 'hideAdditionalPages',
            label: 'Ocultar páginas adicionais'
          },
          {
            type: 'switch',
            model: 'hideCallToActionButton',
            label: 'Ocultar Botão de Ação'
          },
          {
            type: 'text-field',
            model: 'callToActionButtonText',
            label: 'Texto do Botão de Ação'
          },
          {
            type: 'text-field',
            model: 'callToActionButtonLink',
            label: 'Link do Botão de Ação'
          },
          {
            type: 'switch',
            model: 'callToActionButtonTargetBlank',
            label: 'Nova janela'
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }
}

export default entity
