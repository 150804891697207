import accountsRoutes from './accounts'
import authRoutes from './auth'
import clientsRoutes from './clients'
import dashboardsRoutes from './dashboards'
import forbiddenRoutes from './forbidden'
import headlessRoutes from './headless'
import modulesRoutes from './modules'
import usersRoutes from './users'
import workflowsRoutes from './workflows'

export default [
  ...accountsRoutes,
  ...authRoutes,
  ...clientsRoutes,
  ...dashboardsRoutes,
  ...forbiddenRoutes,
  ...headlessRoutes,
  ...modulesRoutes,
  ...usersRoutes,
  ...workflowsRoutes
]
