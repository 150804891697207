const entity = {
  name: {
    singular: 'Evento',
    plural: 'Eventos'
  },
  styles: {
    gridTemplateColumns: '30% 1fr',
    gridTemplateAreas: '"main secondary"'
  },
  collection: 'events',
  tableHeaders: [
    { title: 'Título', value: 'title', type: 'string' },
    { title: 'Url Amigável', value: 'slug', type: 'string' },
    { title: 'Data ', value: 'dateBegin', type: 'date' },
    { title: 'Atualizado em', value: 'updatedAt', type: 'date' },
    { title: 'Entrada ', value: 'dateIn', type: 'date' },
    { title: 'Saída', value: 'dateOut', type: 'date' },
    { title: 'Publicado', value: 'published', type: 'boolean' }
  ],
  model: {
    title: '',
    summary: '',
    fileTeaser: '',
    fileTeaserMobile: '',
    dateBegin: null,
    dateEnd: null,
    body: '',
    styles: '',
    slug: '',
    attendees: '',
    published: false
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      {
        type: 'url',
        label: 'Url amigável',
        model: 'slug',
        required: true
      },
      {
        type: 'date',
        label: 'Início',
        model: 'dateBegin',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'date',
        label: 'Término',
        model: 'dateEnd',
        required: false,
        class: 'xs12 sm12 md6 col-lg-6'
      },
      {
        type: 'switch',
        model: 'published',
        label: 'Publicado'
      }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Conteúdo',
        fields: [
          {
            type: 'text-field',
            label: 'Título',
            model: 'title',
            required: true
          },
          {
            type: 'text-field',
            label: 'Resumo',
            model: 'summary',
            required: false
          },
          {
            type: 'single-file',
            label: 'Imagem do Topo',
            model: 'fileTeaser',
            hideInput: true,
            class: 'xs12 sm12 md6 col-lg-6'
          },
          {
            type: 'single-file',
            label: 'Mobile',
            model: 'fileTeaserMobile',
            hideInput: true,
            class: 'xs12 sm12 md6 col-lg-6'
          },
          {
            type: 'editor',
            label: 'Conteúdo',
            model: 'body',
            required: false
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }
}

export default entity
