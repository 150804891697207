import entity from '../../../models/cms/sections'

export default [
  {
    path: '/sections',
    meta: {
      entity,
      requiresPermissionTo: 'sections',
      crud: 'list'
    },
    component: () =>
      import(
        /* webpackChunkName: "sections" */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/sections/new',
    meta: {
      entity,
      requiresPermissionTo: 'sections',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: "sections" */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/sections/:id',
    meta: {
      entity,
      requiresPermissionTo: 'sections',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: "sections" */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
