import entity from '../../../models/school/parents'

export default [
  {
    path: '/parents',
    meta: {
      entity,
      requiresPermissionTo: 'parents',
      crud: 'list'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/parents/new',
    meta: {
      entity,
      requiresPermissionTo: 'parents',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/parents/:id',
    meta: {
      entity,
      requiresPermissionTo: 'parents',
      crud: 'form'
    },
    component: () =>
      import(
        /* webpackChunkName: 'banners' */ '@/views/dynamic/DynamicIndex.vue'
      )
  }
]
