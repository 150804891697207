const entity = {
  name: {
    singular: 'Payment',
    plural: 'Payments'
  },
  collection: 'payments',
  tableHeaders: [
    { title: 'Name', value: 'name' },
    { title: 'Contact Name', value: 'contactName' },
    { title: 'Contact Email', value: 'contactEmail' },
    { title: 'Contact Phone', value: 'contactPhone' }
  ],
  model: {
    name: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactMobilePhone: '',
    billingName: '',
    billingEmail: '',
    billingPhone: '',
    billingMobilePhone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    notes: ''
  },
  config: {
    name: 'Config',
    hasPreview: true,
    fields: [
      { label: 'Name', model: 'name', class: 'col-lg-12' },
      { label: 'address', model: 'address', class: 'col-lg-12' },
      { label: 'city', model: 'city', class: 'col-lg-6' },
      { label: 'state', model: 'state', class: 'col-lg-3' },
      { label: 'zip', model: 'zip', class: 'col-lg-3' },
      { label: 'Notes', model: 'notes', class: 'col-lg-12', type: 'textarea' }
    ]
  },
  schema: {
    groups: [
      {
        name: 'Contact Info',
        fields: [
          {
            type: 'text-field',
            label: 'Name',
            model: 'contactName',
            class: 'col-lg-12'
          },
          {
            type: 'text-field',
            label: 'Email',
            model: 'contactEmail',
            class: 'col-lg-12'
          },
          {
            type: 'text-field',
            label: 'Phone',
            model: 'contactPhone',
            class: 'col-lg-6'
          },
          {
            type: 'text-field',
            label: 'Mobile Phone',
            model: 'contactMobilePhone',
            class: 'col-lg-6'
          }
        ]
      },
      {
        name: 'Billing Info',
        fields: [
          {
            type: 'text-field',
            label: 'Name',
            model: 'billingName',
            class: 'col-lg-12'
          },
          {
            type: 'text-field',
            label: 'Email',
            model: 'billingEmail',
            class: 'col-lg-12'
          },
          {
            type: 'text-field',
            label: 'Phone',
            model: 'billingPhone',
            class: 'col-lg-6'
          },
          {
            type: 'text-field',
            label: 'Mobile Phone',
            model: 'billingMobilePhone',
            class: 'col-lg-6'
          }
        ]
      }
    ]
  },
  formOptions: {
    validateAfterLoad: false,
    validateAfterChanged: false,
    validateAsync: false
  }
}

export default entity
